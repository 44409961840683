import './Newsletter.scss'

const Newsletter = () => {
  return (
    <div className='container'>
      <div className="row">
        <div className="grid-col-12">
          <div className="newsletter max-width-background hero-panel flex-column">
            <div className="bg-art absolute-fill overflow-hidden order1 ***rel-sm***">
              <div className="absolute-fill grid-col-6 offset6 bg-no-repeat" id="exBG4">

              </div>
              <div className="absolute-fill bg-color"></div>
            </div>
            <div className="container flex panel-height-short">
              <div className="row flex-self-stretch hero-panel-content rel">
                <div className="grid-col-6 grid-col-6-md grid-col-12-sm flex flex-self-stretch flex-items-center justify-center order1 ***hide-sm***"></div>
                <div className="grid-col-6 grid-col-6-md grid-col-6-sm rel flex-self-center pad-t-xl pad-b-xl pad-t-sm-sm pad-b-sm-sm">
                  <div className="newsletter-content">
                    <h3 className='heading-lg font-bold mar-b-xxs'>Want to stay in the loop?</h3>
                    <p className='mar-b-xs'>
                      Sign up for the latest community updates and stay up-to-date on everything AT&T Fiber,®
                      like marketing materials, contests, and more.
                    </p>
                    <a href="https://att.com/subscribe" target="_blank" rel="noreferrer" className="btn-primary">Join Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default Newsletter