import React from 'react'
import { useSelector } from 'react-redux'

import { CardshellRow } from '@att-bit/duc.components.cardshell-row'
import { Cardshell, CardShellVariant } from '@att-bit/duc.components.cardshell'
import { IconSvg } from '@att-bit/duc.components.icon-svg'
import Breadcrumbs from 'components/Breadcrumbs'

import './Tiles.scss'

const Tiles = () => {
  const { subcategory } = useSelector(state => state.application.selection)

  return (
    <div className="pad-b-xs">
      <div className="container">
        <div className="row">
          <div className="grid-col mar-t-xs">
            <Breadcrumbs />
          </div>
        </div>
        <div className="row tiles">
          <div className="grid-col-12">
            <p className="mar-b-xxs">Select your issue category:</p>
            <CardshellRow className="pad-t-none pad-b-none">
              {subcategory.tiles.map((tile, index) => {
                return (
                  <div key={`cat_${index}`} className="sm-card grid-col-3 pad-b-xs-sm pad-t-none-sm">
                    <Cardshell
                      variant={CardShellVariant.LINK}
                      isChecked={false}
                      href={tile.link}
                      target='_blank'
                    >
                      <div className="rel text-center text-left-sm flex-sm pad-t-none-sm flex-items-center pad-l-md-sm">
                        <div className="image-wrapper">
                          <IconSvg
                            size='LG'
                            svgUrl={`/icons/icon-${tile.icon}.svg`}
                            aria-label={tile.name}
                            aria-hidden="false"
                            accentColor={true}
                            pictogram={true}
                          />
                        </div>
                        <div className='type-sm card-text'>{tile.name}</div>
                      </div>
                    </Cardshell>
                  </div>
                )
              })}
            </CardshellRow>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tiles