import Breadcrumbs from 'components/Breadcrumbs'
import React from 'react'
import { useSelector } from 'react-redux'
import './Complete.scss'
import { useHistory } from 'react-router-dom'

const Complete = () => {

  const history = useHistory()

  const { roles, ticket } = useSelector(state => state.application)
  const { roleIndex } = useSelector(state => state.application.selection)

  return (
    <div className="complete container">
      <div className="row">
        <div className="grid-col mar-t-xs">
          <Breadcrumbs level={1} />
        </div>
      </div>
      <div className="row">
        <div className="grid-col-12">
          <div className=" complete-content pad-t-xl-sm pad-b-xl-sm pad-t-md-md pad-b-md-md pad-t-md-lg pad-b-md-lg pad-l-md pad-r-md">

            <h4 className="heading-sm mar-b-xxs">Thank you for your submission.</h4>
            <p className="type-sm font-regular">You will receive a confirmation email and updates to the address you provided.</p>
            <p className="type-sm line-h-tight mar-b-md">Your case number is <strong>{ticket || "Ticket Number"}</strong></p>

            <button className="btn-primary" onClick={() => {
              history.push(`/${roles[roleIndex].slug}`)
            }}>Create another request</button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Complete