import { useSelector } from 'react-redux'
import './Subcategories.scss'
import { GA_EVENTS, ReactGA } from 'util/analytics'
import Breadcrumbs from 'components/Breadcrumbs'
import { useHistory } from 'react-router-dom'
import { current } from '@reduxjs/toolkit'

const Subcatagories = () => {

  const history = useHistory()

  const categories = useSelector(state => {
    const app = state.application
    return app.roles[app.selection.roleIndex].categories
  })

  const { roles, subcategories } = useSelector(state => state.application)
  const { role, categoryIndex, roleIndex } = useSelector(state => state.application.selection)
  const currentSubcategories = subcategories
    .filter(item => item.hideLink === undefined || item.hideLink === false)
    .filter(item => item.role === role && item.category === categories[categoryIndex]?.id).sort((a, b) => a.subcategory.localeCompare(b.subcategory));

  const groups = currentSubcategories.map( (item) => item.group ).filter((value, index, array) => array.indexOf(value) === index)
  const groupSubCategores = groups.map((item) => {
    return currentSubcategories.filter(sub => sub.group === item)
  })

  let selectedCTA = null

  const handleSubcategory = ({ role, category, ...rest }, itemIndex) => {
    ReactGA.event(GA_EVENTS.pageview, {
      page_title: rest.subcategory,
      page_location: `${role}/new/${category}/${rest.subcategory}`
    })

    const selectedCategory = categories[categoryIndex]
    const selectedRole = roles[roleIndex]
  

    ReactGA.event(GA_EVENTS.acc_subcategory_selected, {
      acc_role_id: selectedRole?.id,
      acc_role_name: selectedRole?.name,
      acc_category_id: selectedCategory?.id,
      acc_category_name: selectedCategory?.name,
      acc_subcategory_id: rest.subcategory ? rest.subcategory?.toLowerCase() : '',
      acc_subcategory_name: rest.subcategory,
      acc_action: rest.action,
    })

    history.push(`/${selectedRole.slug}/new/${selectedCategory.slug}/${rest.id}`)
  }

  return (
    <div className="Subcategories flex flex-items-center justify-center">
      <div className='container'>

        {categoryIndex <= categories.length - 1 && categoryIndex > -1 && <div className="row flex-wrap">
          <div className="grid-col-12 mar-t-xs">
            <Breadcrumbs level={2} />
          </div>
          <div className="grid-col-11">
            <h4 className="heading-sm font-regular mar-b-xs">Select your issue category:</h4>
            <div className={groups.length === 2 ? 'row' : ''}>
              {groups.map( (group, groupIndex) => {
              return <div key={group} className={`mar-b-xs ${groups.length === 2 ? 'grid-col-6 nopad' : ''}`}>
                {group && group !== "" && <h2 className='mar-b-xs font-bold'>{group}</h2>}
                <div className={`columns ${groups.length === 2 ? 'count-1 ' : ''} nopad`}>
                  {groupSubCategores[groupIndex].map((sub, index) => {
                    if(sub.id === 'bulk-prelaunch-support') {
                      selectedCTA = {
                        sub,
                        index,
                      }
                    }
                    return (
                      <div key={`sublink_${group}_${sub.role}_${sub.category}_${index}`} className="mar-b-xs">
                        <div className="pad-r-sm">
                          <a href="/" className="link-text3 font-regular solo type-sm" onClick={(e) => {
                            e.preventDefault();
                            handleSubcategory(sub, index)
                          }}>{sub.subcategory}</a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              
            })}
            </div>
            {selectedCTA && <div>
              Questions? Click <a href="/" onClick={(e) => {
                e.preventDefault();
                handleSubcategory(selectedCTA.sub, selectedCTA.index)
              }}>here</a> for additional support. 
            </div>}
          </div>
        </div>}
      </div>

    </div>
  )
}

export default Subcatagories