import { createSlice } from "@reduxjs/toolkit";
import { CATEGORIES } from "store/contstants";

const initialState = {
  view: CATEGORIES,
  ready: false,
  roles: null,
  faqs: null,
  subcategories: null,
  ticket: null,
  faqIds: null,
  faqCurrent: null,
  selection: {
    role: "",
    roleIndex: -1,
    category: "",
    categoryIndex: -1,
    subcategory: null,
  },
  seeAllFaqs: false
};

function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const counterSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    initData: (state, action) => {
      state.ready = true;
      state.roles = action.payload.roles;

      const faqs = [...action.payload.faqs];
      const faqIds = {};
      faqs.forEach((item, index) => {
        item.id = `faq_${index + 1}`;
        faqIds[item.id] = false;
      });
      state.faqIds = faqIds;
      state.faqs = faqs;

      const subs = [...action.payload.subcategories];
      subs.forEach((item, index) => {
        item.id = slugify(item.subcategory);
      });
      state.subcategories = subs;
      state.seeAllFaqs = false;
    },

    resetState: (state) => {
      state.view = CATEGORIES;
      state.selection = {
        category: "",
        categoryIndex: -1,
        subcategory: null,
      };
    },
    updateFaq: (state, action) => {
      const { id, isOpen } = action.payload;
      state.faqIds[id] = isOpen;
    },
    updateFaqs: (state, action) => {
      const faqIds = {};
      state.faqs.forEach((item, index) => {
        faqIds[`faq_${index + 1}`] = action.payload === `faq_${index + 1}`;
      });
      state.faqIds = faqIds;
    },
    toggleFaqs: (state, action) => {
      const faqIds = {};
      state.faqs.forEach((item, index) => {
        faqIds[`faq_${index + 1}`] = action.payload;
      });
      state.faqIds = faqIds;
    },
    updateSeeAllFaqs: (state, action) =>{
      state.seeAllFaqs = action.payload;
    },
    updateState: (state, action) => {
      //update current view
      if (action.payload.view) {
        state.view = action.payload.view;
      }

      if (action.payload.ticket) {
        state.ticket = action.payload.ticket;
      }

      //update user selections
      if (action.payload.selection) {
        const selection = {
          ...state.selection,
          ...action.payload.selection,
        };
        state.selection = selection;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initData,
  updateState,
  updateFaq,
  updateFaqs,
  toggleFaqs,
  resetState,
  updateSeeAllFaqs,
} = counterSlice.actions;

export default counterSlice.reducer;
