import { default as AttLogo } from 'images/svg/att-logo.svg'
import { default as GlobeLogo } from 'images/svg/att-globe.svg'


import { useDispatch, useSelector } from 'react-redux'
import './Header.scss'
import { IconSvg, IconSvgSizes } from '@att-bit/duc.components.icon-svg'
import { TextField } from '@att-bit/duc.components.text-field'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { updateFaqs, updateSeeAllFaqs } from 'store/slices/applicationSlice'
import { useWindowWidth } from '@react-hook/window-size'

const limitText = (text) => {

  const TEXT_LENGTH = 47
  if (text.length > TEXT_LENGTH) {
    return text.substring(0, TEXT_LENGTH).trim() + '...'
  }
  return text

}

const Header = () => {

  const { faqs, roles, subcategories } = useSelector(state => state.application)
  const { roleIndex } = useSelector(state => state.application.selection)

  const history = useHistory()
  const dispatch = useDispatch()

  const windowWidth = useWindowWidth()
  const [mobileSearch, setMobileSearch] = useState(false)

  const [search, setSearch] = useState('')

  const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="18.414" height="19.93" viewBox="0 0 18.414 19.93">
  <g id="Group_3" data-name="Group 3" transform="translate(-1.324 2.049)">
    <g id="Group_82" data-name="Group 82" transform="translate(1.324 -2.049)">
      <path id="Path_25" data-name="Path 25" d="M2638,776l4.862,5.581" transform="translate(-2625.858 -763.062)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(14.84) rotate(90)" fill="none" stroke="#fff" stroke-width="2">
        <circle cx="7.42" cy="7.42" r="7.42" stroke="none"/>
        <circle cx="7.42" cy="7.42" r="6.42" fill="none"/>
      </g>
    </g>
  </g>
</svg>
`
  const searchRef = useRef(null)

  //handle clicking outside the search
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef && searchRef.current && !searchRef.current.contains(event.target)) {
        setSearch('')
        setMobileSearch(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [searchRef])

  const roleName = roles[roleIndex].name
  const roleId = roles[roleIndex].id
  const roleSlug = roles[roleIndex].slug
  const categories = roles[roleIndex].categories
  const term = search.toLowerCase()

  const FILTERED_SUBCATEGORIES = subcategories.filter((sub) => {
    return sub.role === roleId &&
      (sub.subcategory.toLowerCase().search(term) > -1 ||
        sub.meta.toLowerCase().search(term) > -1)
  }).slice(0, 5);

  const FILTERED_FAQS = faqs.filter((faq) => {
    return faq.roles === undefined || faq.roles.includes(roleId)
  }).filter((faq) => {
    return faq.title.toLowerCase().search(term) > -1 || faq.keywords.toLowerCase().search(term) > -1
  }).slice(0, 5);

  return (
    <div className="header full-background bg-gray-900">
      <div className="container">
        <div className="row">
          <div className="grid-col flex flex-items-center justify-between">
            <div className='flex flex-items-center'>
              <button className='logo' onClick={() => {
                history.push(`/${roleSlug}`)
              }}>
                <img className='mobile-hide' src={AttLogo} alt="Logo" />
                <img className='mobile-show' src={GlobeLogo} alt="Logo" />
              </button>
              {(windowWidth > 610 || mobileSearch === false) && <p className="font-regular type-sm color-white pad-l-xs">Connected Communities Portal</p>}
            </div>
            <div ref={searchRef} className='search'>
              <div className='flex'>
                {(windowWidth > 767 || mobileSearch) && <div className="rel search-inner">
                  <TextField placeHolder='Search' onKeyUp={(e) => {
                    if (e.key === 'Escape') {
                      setSearch('')
                      setMobileSearch(false)
                      e.target.blur()
                    }
                  }} value={search} onChange={(e) => {
                    setSearch(e.target.value);
                    dispatch(updateSeeAllFaqs(true));
                  }} />
                  {search.length > 2 && <button onClick={() => {
                    setSearch('')
                    setMobileSearch(false)
                  }} aria-label="Clear" className="clear-btn btn-reset flex flex-centered absolute top right">
                    <span className="flex icon-close height-md-all width-md-all round transparent"></span>
                  </button>}
                </div>}
                <button onClick={() => {
                  if (mobileSearch) {
                    setSearch('')
                  }
                  setMobileSearch(!mobileSearch)
                }} className='search-button btn-reset'>
                  <IconSvg svgNodeStr={icon} size={IconSvgSizes.SM} />
                </button>
              </div>
              {search.length > 2 && <div className='search-card'>
                <div className="card border-shadow bg-white overflow-hidden radius-md rel" role="region">
                  <div className="pad-l-xs pad-r-xs pad-t-xs pad-b-xxs">
                    {FILTERED_SUBCATEGORIES.length > 0 && <div className='mar-b-xs'>
                      <div className='type-sm font-bold mar-b-xxxs'>{roleName}</div>
                      <div className='mar-b-xxs'>
                        <hr />
                      </div>
                      {FILTERED_SUBCATEGORIES.map((sub, index) => {

                        const category = categories.find(cat => cat.id === sub.category)

                        return (
                          <div key={`sub_${sub.id}`} className='mar-b-xxs'>
                            <button onClick={() => {
                              history.push(`/${roleSlug}/new/${category?.slug || sub.category}/${sub.id}`)
                              setSearch('')
                            }} className='btn-reset link-text'>{category.name} | {sub.subcategory}</button>
                          </div>
                        )
                      })}

                    </div>}
                    {FILTERED_FAQS.length > 0 && <div className='mar-b-xs'>
                      <div className='type-sm mar-b-xxxs font-bold'>FAQS</div>
                      <div className='mar-b-xxs'>
                        <hr />
                      </div>
                      {FILTERED_FAQS.map((faq, index) => {
                        return (
                          <div key={`faq_${faq.id}`} className='mar-b-xxs'>
                            <button onClick={() => {
                              setSearch('')
                              dispatch(updateFaqs(faq.id))
                              const element = document.getElementById(`${faq.id}`);
                              if (element) {
                                setTimeout(() => {
                                  element.scrollIntoView({
                                    behavior: "smooth",
                                  });
                                }, 300)
                              }
                            }} className='btn-reset link-text'>{limitText(faq.title)}</button>
                          </div>
                        )
                      })}
                    </div>}
                    {FILTERED_FAQS.length === 0 && FILTERED_SUBCATEGORIES.length === 0 && <div className='mar-b-xxs'>
                      <p className='type-sm'>No results available</p>
                    </div>}
                  </div>
                  <div className='search-footer type-xs pad-xs'>
                    <p>Showing results for {roleName}. Change your role to see other content</p>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header