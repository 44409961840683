import './Loading.scss'

const Loading = () => {
  return (
    <div className="loading flex flex-items-center justify-center">
      <div className='flex flex-column flex-items-center text-center'>
        <div className="height-lg-all width-lg-all">
          <svg viewBox="0 0 43 43" focusable="false" className="loading-spinner">
              <g fill="none">
                <circle r="20" cx="21.5" cy="21.5" stroke="#878c94" strokeWidth="3" opacity=".25"/>
                <circle r="20" cx="21.5" cy="21.5" className="loading-spinner-outer" strokeDasharray="89,200" stroke="#009fdb" strokeWidth="3" strokeLinecap="round"/>
              </g>
          </svg>   
        </div>
        <p className="type-sm color-gray-700 mar-t-xxs">Loading...</p>
      </div>
    </div>
  )
}

export default Loading