import { CarouselV2 } from '../Carousel/index.tsx';
import './Hero.scss';

import SlideCards from 'images/reskin/slides/slide-cards.png'
import SlideCard from 'images/reskin/slides/slide-card.png'
import Ribbon from 'images/reskin/slides/slide-ribbon.png'
import BasicSlide from './BasicSlide.jsx';
import LegalModal from 'components/LegalModal/LegalModal.jsx';
import { useState } from 'react';

const Slide1 = () => {
  return (
    <div className='rel card-item slide-1'>
      <div className='card-background radius-lg'>
        <div className="mobile-bg"></div>
      </div>
      <div className='rel flex flex-1 flex-items-center justify-center text-center card-content'>
        <div>
          <h1 className='heading-lg mar-b-xxs'>AT&T Connected Communities Portal</h1>
          <p>Keeping our communities connected.</p>
        </div>
      </div>
    </div>
  )
}

const Slide2 = ({ setScreen }) => {
  return (
    <div className='rel slide-2'>
      <div className='slide-background'>
        <img className='ribbon' src={Ribbon} alt="" />
      </div>
      <div className='rel flex flex-1 text-center card-content'>
        <div className='slide-card card1'>
          <h2>Residents can get fast internet, plus powerful Wi-Fi they can rely on starting at 300Mbps for</h2>
          <p><span className='large'>$55</span><span className='font-bold'>/mo.</span> plus taxes.</p>
          <p className='small'>Price after $5/mo. AutoPay & Paperless Billing discount (within 2 bills). Monthly State Cost Recovery Charge in TX, OH, NV applies. One-time installation charge may apply.<br />Ltd. availability/areas. <button className='btn-reset type-xs link-text3 color-white font-regular' onClick={() => setScreen('2a')}>See offer details</button>.</p>
        </div>
        <div className='slide-plus flex flex-items-center justify-center'>
          <svg width="34.928" height="36.282" viewBox="0 0 34.928 36.282">
            <path id="Path_40" d="M5.64-23.95v-5.076H20.491V-44.666h5.226v15.641H40.568v5.076H25.717V-8.384H20.491V-23.95Z" transform="translate(-5.64 44.666)" fill="#fff" />
          </svg>
        </div>
        <div className='slide-card card2'>
          <h2>They get to pick a<br /><span className='large'>$50 Reward</span><br />when they order online</h2>
          <p className='small'>Redemption req’d. $50 w/ 300. <button className='btn-reset type-xs link-text3 color-white font-regular' onClick={() => setScreen('2b')}>See details</button>.</p>
          <img className="cards-image" src={SlideCards} alt="Cards" />
        </div>
        <div className='slide-plus flex flex-items-center justify-center'>
          <svg width="34.928" height="36.282" viewBox="0 0 34.928 36.282">
            <path id="Path_40" d="M5.64-23.95v-5.076H20.491V-44.666h5.226v15.641H40.568v5.076H25.717V-8.384H20.491V-23.95Z" transform="translate(-5.64 44.666)" fill="#fff" />
          </svg>
        </div>
        <div className='slide-card card3'>
          <h2><span className='large'>A $50 AT&T Visa® Reward Card</span><br />when they use promo code:<br /><span className='med'>EXTRA50</span></h2>
          <p className='small'>Online only. Redemption req’d. Internet 300+. <button className='btn-reset type-xs link-text3 color-white font-regular' onClick={() => setScreen('2c')}>See details</button>.</p>
          <img className="card-image" src={SlideCard} alt="Card" />
        </div>
      </div>
    </div>
  )
}

const Slide3 = ({ setScreen }) => {
  return (
    <div className='rel card-item'>
      <BasicSlide imageClassName='slide3-image'>
        <div className='row text-center'>
          <div className='grid-col-10 offset1 grid-col-12-md offset0-md grid-col-12-sm offset0-sm'>
            <h3 className='heading-lg mar-b-xxs'>Refer a friend. Everybody wins!</h3>
            <p className='mar-b-sm'>Get <strong>$25</strong> for each referral. Plus, your friends get <strong>$75</strong> when they sign-up for AT&T Fiber<sup>&reg;</sup> or AT&T Wireless.</p>
            <a href="https://www.att.com/fiber4friend" target="_blank" rel="noreferrer" className='btn-primary mar-t-xs-sm mar-b-xs'>How to refer</a>
            <p className='type-xs'>$75 bill credit for new fiber or postpaid wireless<br /> customers only. AT&T Fiber: Ltd. avail/areas.<br /><button className='btn-reset type-xs link-text3 color-black font-regular' onClick={() => setScreen('3a')}>See offer details</button>.</p>
          </div>
        </div>
      </BasicSlide>
    </div>
  )
}

const Slide5 = ({ setScreen }) => {
  return (
    <div className='rel card-item slide-5'>
      <BasicSlide imageClassName='slide5-image'>
        <div className='row text-center'>
          <div className='grid-col-10 offset1 grid-col-12-md offset0-md grid-col-12-sm offset0-sm'>
            <h3 className='heading-lg mar-b-xxs'>Join the <span className='title-wrap'>AT&T Fiber Challenge</span></h3>
            <p className='subheading mar-b-sm'>Enter now for a chance to win a <br /><strong>$500 gift card</strong> for your community.</p>
            <a href="https://www.att.com/fiberchallenge" target="_blank" rel="noreferrer" className='btn-primary mar-t-xs-sm mar-b-xs'>att.com/fiberchallenge</a>
            <p className='type-xs'>For complete contest rules. <a href="https://www.att.com/fiberchallengerules" target="_blank" rel="noreferrer" className='link-text3 color-white mar-t-xs-sm mar-b-xs'>click here</a>.</p>
          </div>
        </div>
      </BasicSlide>
    </div>
  )
}

const Slide4 = () => {
  return (
    <div className='rel card-item'>
      <BasicSlide imageClassName='slide4-image'>
        <div className='row'>
          <div className='grid-col-10 offset2 grid-col-12-md offset0-md grid-col-12-sm offset0-sm'>
            <h3 className='heading-lg mar-b-xxs'>Leasing agent spotlight</h3>
            <h4 className='heading-md'>Melissa Celestino </h4>
            <p className='type-sm'>
              <em>Casa Mira View<br />
                San Diego, California</em>
            </p>
            <p>
              “Casa Mira View loves referring AT&T Fiber.
              Our personal AT&T Concierges George & Dan
              are always there to tailor fit residents’ needs.”
            </p>
          </div>
        </div>
      </BasicSlide>
    </div>
  )
}



const Hero = () => {

  const [screen, setScreen] = useState('')

  return <div className='hero mar-t-xxs'>
    <CarouselV2 cardsVisible={1} onDark={true} data-test-id="carousel-1up" autoScroll="6000">
      <Slide1 />
      {/* <Slide5 setScreen={setScreen} /> */}
      <Slide2 setScreen={setScreen} />
      <Slide3 setScreen={setScreen} />
      <Slide4 setScreen={setScreen} />
    </CarouselV2>
    <LegalModal screen={screen} setScreen={setScreen} />
  </div>
}

export default Hero
