import './BasicSlide.scss';

const BasicSlide = ({ children, imageClassName = "" }) => {
  return (
    <div className="basic-slide hero-panel flex-column theme-accent-bg">
      <div className="bg-art absolute-fill overflow-hidden order1 ***rel-sm***">
        <div className="absolute-fill bgcolor"></div>
        <div className={`absolute-fill grid-col-5 offset7 bg-no-repeat zoomable ${imageClassName}`} id="exBG4">
          <div id="embrace-exBG4"></div>
        </div>
      </div>
      <div className="container flex panel">
        <div className="row flex-self-stretch hero-panel-content rel">
          <div className="grid-col-5 grid-col-12-md flex flex-self-stretch flex-items-center justify-center order1 ***hide-sm***"></div>
          <div className="grid-col-7 grid-col-12-md rel flex-self-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicSlide
