import { default as GlobeLogo } from 'images/svg/att-globe.svg'

const year = new Date().getFullYear()

const Footer = () => {
  return (
    <div className="full-background bg-gray-200">
      <div className="container">
        <div className="row">
          <div className="grid-col flex flex-items-center">
            <img width="30" src={GlobeLogo} alt="Logo" />
            <p className="type-legal pad-l-sm">Copyright ©{year} AT&T Intellectual Property. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer