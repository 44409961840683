import './MarketingMaterials.scss'

const MarketingMaterials = () => {
  return <div className='marketing-materials container'>
    <div className='row'>
      <div className="grid-col-4">
        <div>
          <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=HNdB57bGsEeAPA87MrB1VsgNOcJRRB9HuOnwmVtz-K1UOEdaV05KQUU3RTZZV1FRRzhTS0Y2STkzNC4u&wdLOR=c340D07D3-03F3-453E-BC22-A6D96E8A3627" rel="noreferrer" target="_blank" className='marketing-card card no-hover border-shadow radius-lg rel bgcolor theme-base-bg' tabIndex={0}>
            <h3 className='heading-lg mar-b-xxs'>Order Materials</h3>
          </a>
        </div>
        <div className="pad-xxs text-center">
          <p className='mar-b-xxs'>Order marketing materials for your community</p>
        </div>
      </div>
      <div className="grid-col-4">
        <a href="/downloads/FiberFocus-2024-03.pdf" target="_blank" className='marketing-card card no-hover border-shadow radius-lg rel bgcolor theme-base-bg' tabIndex={0}>
          <div>
            <h4 className='type-base font-bold'>AT&T Connected Communities®</h4>
            <h3 className='heading-lg mar-b-xxs'>Fiber Focus</h3>
          </div>
        </a>
        <div className="pad-xxs text-center">
          <p className='mar-b-xxs'>View and download our latest newsletter</p>
        </div>
      </div>
      <div className="grid-col-4">
        <div>
          <a href="/downloads/DigitalAssets.zip" targe="_blank" className='marketing-card card no-hover border-shadow radius-lg rel bgcolor theme-base-bg' tabIndex={0}>
            <h3 className='heading-lg mar-b-xxs'>Digital Assets</h3>
          </a>
        </div>
        <div className="pad-xxs text-center">
          <p className='mar-b-xxs'>Download Digital Logos, Banner or Tiles for your property's website</p>
        </div>
      </div>
    </div>
  </div>
}

export default MarketingMaterials