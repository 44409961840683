import Header from 'components/Header'
import Footer from 'components/Footer'
import Hero from 'components/Hero'
import './layout.scss'
import MarketingMaterials from 'components/MarketingMaterials/MarketingMaterials'
import Newsletter from 'components/Newsletter/Newsletter'
import Faq from 'components/Faq/Faq'
import HelpfulLinks from 'components/HelpfulLinks/HelpfulLinks'
import Modal from 'components/Modal/Modal'
import PromoModal from 'components/PromoModal/PromoModal'
import { useSelector } from "react-redux";

const HR = () => {
  return <div className='container pad-l-md pad-r-md mar-b-md mar-t-md'>
    <div className="hr-rule">
      <hr className="type-xs font-bold" />
    </div>
  </div>
}

const Layout = ({ children }) => {

  const { roleIndex } = useSelector(state => state.application.selection)

  if (roleIndex < 0) {
    return null
  }

  return (
    <div className='default-layout flex flex-column'>
      <div className='flex-0'>
        <Header />
        <Hero />
      </div>
      <div id="main" className='main flex-1'>
        {children}
      </div>
      <div>

        <HR />

        <MarketingMaterials />

        <HR />

        <Newsletter />

        <HR />

        <HelpfulLinks />

        <HR />

        <Faq />
      </div>

      <div className='flex-0'>
        <Footer />
      </div>

      <Modal />

      <PromoModal />

    </div>
  )
}

export default Layout