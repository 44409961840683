import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ReactGA from 'react-ga4';

import { store } from 'store'
import { Provider } from 'react-redux'
import 'util/patchWarning'

const GA_MEASUREMENT_ID =  window.location.hostname.match('att.com') === null 
  ? 'G-GSMWW3YKJ9'
  : 'G-QH22WR9J3H' 

ReactGA.initialize(GA_MEASUREMENT_ID, {
  gtagOptions: {
    send_page_view: false,
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
