import './LegalModal.scss'
import { Modal, ModalSize } from '@att-bit/duc.components.modal';


const Legal2A = () => {
  return <>
    <p>
      <strong>INTERNET OFFER: Autopay & Paperless Bill Discount:</strong> Pay full plan cost until discount starts w/in 2 bill cycles. Must maintain valid email address to continue discount.&nbsp;
      <strong>Taxes & Fees:</strong> Up to $99 installation fee may apply, plus tax where applicable. Taxes also apply. For one time AT&T transactional fees, see <a className='link-text3' target="_blank" rel="noreferrer" href="https://att.com/fees">att.com/fees</a> for details.&nbsp;
      <strong>Pricing subject to change.</strong> Subj. to Internet Terms of Service at <a className='link-text3' href='https://www.att.com/internet-terms' target='_blank' rel='noreferrer'>att.com/internet-terms</a>.
    </p>

  </>
}

const Legal2B = () => {
  return <>
    <p><strong>REWARD OFFER: Subj. to change and may be discontinued at any time. Online only at att.com. Reward Value:</strong> $50 w/300. Residential customers residing in select ZIP codes only who purchase an AT&T Fiber plan will be able to choose from a prepaid AT&T Visa Reward Card or a gift Sard from these merchants: Amazon, Apple, Google Play, Starbucks, Target, or Uber at the time of redemption on the AT&T Reward Center. Retail merchant gift card terms and conditions will be available at <a className='link-text3' target="_blank" rel="noreferrer" href="https://www.att.com/rewardcenter">www.att.com/rewardcenter</a>. <strong>Redemption required:</strong> Will be sent email or letter with redemption info. Redemption req'd. w/in 75 days from reward notification email or mail date Card delivered w/in 3-4 weeks after redemption to customers who maintain and pay for qualifying service through reward fulfillment. The AT&T Visa® Reward Card expires at month-end 6 mos after issuance. For Cardholder Agreement, go to <a className='link-text3' target="_blank" rel="noreferrer" href="https://rewardcenter.att.com">rewardcenter.att.com</a>. Card issued by The Bancorp Bank NA, Member FDIC, pursuant to a license from Visa U.S.A. Inc. and can be used everywhere Visa debit cards are accepted in the United States, District of Columbia, US Virgin Islands, & Puerto Rico. No cash access. The Bancorp Bank NA; Member FDIC. The Bancorp Bank N.A. does not endorse or sponsor & is not affiliated in any way with any product or service offered by AT&T.</p>
    <p><strong>Offers may not be combined with certain other promotional offers on the same services and may be modified or discontinued at any time without notice. Other conditions may apply to all offers.</strong></p>
  </>
}

const Legal2C = () => {
  return <>    
    <p><strong>REWARD CARD OFFERS: Subj. to change and may be discontinued at any time. Online only at <a className='link-text3' href='https://www.att.com' target='_blank' rel='noreferrer'>att.com</a>. </strong> $50 Reward Card w/Internet 300. Add'l $50 when entering promo code EXTRA50 at checkout. Residents of select multi-dwelling units not eligible.</p>
    <p>Reward Card: Will be sent email or letter for each card offer with redemption info. Redemption req'd. w/in 75 days from reward notification email or mail date Card delivered w/in 3-4 weeks after redemption to customers who maintain and pay for qualifying service through reward fulfillment. Card expires at month-end 6 mos after issuance. For Cardholder Agreement, go to rewardcenter.att.com. Card is issued by The Bancop Bank N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. and can be used everywhere Visa debit cards are accepted in the United States, District of Columbia, US Virgin Islands, and Puerto Rico. No cash access. The Bancorp Bank N.A. does not endorse or sponsor and is not affiliated in any way with any product or service offered by AT&T.</p>
    <p>AT&T Internet: Subj. to Internet Terms of Service at <a className='link-text3' href='https://www.att.com/internet-terms' target='_blank' rel='noreferrer'>att.com/internet-terms</a>.</p>
    <p>Offers may not be combined with certain other promotional offers on the same services and may be modified or discontinued at any time without notice. Other conditions may apply to all offers.</p>
    <p>©2024 AT&T Intellectual Property. All rights reserved. AT&T, the AT&T logo, and all other AT&T marks contained herein are trademarks of AT&T Intellectual Property and/or AT&T affiliated companies. Subsidiaries and affiliates of AT&T Inc. provide products and services under the AT&T brand. All other trademarks are the property of their respective owners.</p>
  </>
}

const Legal3A = () => {
  return <>
    <p><strong><u>AT&T Refer a Friend:</u> Subj to change and may be discontinued at any time</strong>. Existing AT&T Fiber and AT&T wireless customers must request referral link (or QR code) from the Refer a Friend portal and provide it to new customer. New customer must order new fiber internet or postpaid wireless service and activate Referral Credit Offer to ensure both accounts are credited. <strong>Bill Credits:</strong> $25 bill credit for existing customer per referral (new fiber or wireless). $75 bill credit for new customer (fiber or wireless). Lifetime maximum of 10 referral credits per customer. If new customer orders both fiber and wireless svcs, existing and new customer eligible for one credit only. New customer receives bill credit on new account on first bill cycle; remaining credit may be rolled over to next bill if prorated bill amount is less than amount of credit AT&T employees not eligible. Credits have no cash value, are not redeemable for cash or any cash equivalent and are not transferable or refundable.</p>
    <p>For more info, visit <a className='link-text3' href="https://www.att.com/referral" target='_blank' rel='noreferrer'>att.com/referral</a></p>
  </>
}



export const LegalModal = ({ screen, setScreen }) => {

  const handleClose = () => {
    setScreen('')
  }

  return (
    <>
      {screen !== "" &&
        < Modal
          onClose={() => handleClose()}
          size={ModalSize.XLARGE}
          aria-modal="true"
          scrimZindex={20}
        >
          <Modal.Body>
            <div className='legal-modal-body'>
              {screen === '2a' && <Legal2A />}
              {screen === '2b' && <Legal2B />}
              {screen === '2c' && <Legal2C />}
              {screen === '3a' && <Legal3A />}
            </div>
          </Modal.Body>
        </Modal >
      }
    </>
  );
};

export default LegalModal