import { useSelector } from "react-redux"
import React from 'react'
import { ReactGA, GA_EVENTS } from 'util/analytics'

import './HelpfulLinks.scss'

const NUMBERS = [
  { type: 'phone', content: 'AT&T Community Wi-Fi:', number: '877-755-0625' },
  { type: 'phone', content: 'Bulk:', number: '866-299-6824' },
  { type: 'phone', content: 'Business Call Center:', number: '855-459-0677' },
  { type: 'phone', content: 'Business Hot Spot/Gates/Lockers:', number: '800-331-0500' },
  { type: 'phone', content: 'Customer Self Install:', number: '800-288-2020' },
  { type: 'phone', content: 'Consumer Wireless Support:', number: '800-331-0500', append: 'or 611 from an AT&T Device' },
  { type: 'phone', content: 'Customer Billing:', number: '800-288-2020' },
  { type: 'phone', content: 'DTV Stream Help:', number: '855-671-0410' },
  { type: 'phone', content: 'Instant On Bulk:', number: '844-213-7251' },
  { type: 'phone', content: 'Instant On Non-Bulk:', number: '877-677-1904' },
  { type: 'phone', content: 'Phone Repair:', number: '800-288-2020' },
  { type: 'phone', content: 'Tech Support:', number: '877-722-3755' },
]

const LINKS = [
  { type: 'link', role: 'accEmployee', content: 'ACC DCC Back Office', link: "mailto:accsupport@amcustomercare.att-mail.com" },
  { type: 'link', content: 'AT&T - Customer Support Center', link: "https://www.att.com/support/" },
  { type: 'link', content: 'HSIA Online Ordering', link: "https://www.att.com/internet/" },
  { type: 'link', content: 'Smart Home Manager App', link: "https://www.att.com/support/article/u-verse-high-speed-internet/KM1207739/" },
  { type: 'link', content: 'Wireless Online Ordering', link: "https://www.att.com/wireless/" },
]


const HelpfulLinks = () => {

  const { roles } = useSelector(state => state.application)
  const { role, categoryIndex, roleIndex } = useSelector(state => state.application.selection)

  const selectedRole = roles[roleIndex]

  const categories = useSelector(state => {
    return state.application.roles[roleIndex]?.categories || []
  })

  const selectedCategory = categories[categoryIndex]

  const linkClick = (link) => {
    ReactGA.event(GA_EVENTS.acc_helpful_link_clicked, {
      acc_role_id: selectedRole?.id,
      acc_role_name: selectedRole?.name,
      acc_category_id: selectedCategory?.id,
      acc_category_name: selectedCategory?.name,
      acc_link_url: link.link,
      acc_link_name: link.content,
    })

    const newWindow = window.open(link.link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="container mar-b-md">
      <div className="helpful-links row flex-wrap">
        <div className="grid-col-12">
          <h3 className="heading-lg font-regular">Helpful numbers &amp; links</h3>
        </div>
        <div className="grid-col-8">
          <div className="columns nopad">
            {NUMBERS.map((link, linkIndex) => {
              return (
                <React.Fragment key={`link_group_child${linkIndex}`}>
                  {link.type === 'text' && <p className='type-base pad-r-xs'>{link.content}</p>}
                  {link.type === 'phone' && <p className='type-base pad-r-xs'>{link.content} <a href={`tel:+1-${link.number}`} className="link-text3 solo type-base phone-link">{link.number}</a> {link.append}</p>}
                  {link.type === 'linktext' && <p className='type-base pad-r-xs'>{link.content} <a href={`${link.link}`} target="_blank" className="link-text3 solo type-base phone-link">{link.link}</a></p>}
                  {link.type === 'link' && (link.role === undefined || link.role === role) && <p>
                    <a href="/" onClick={(e) => {
                      e.preventDefault()
                      linkClick(link)
                    }
                    } className="font-regular link-text3 solo type-base">{link.content}</a>
                  </p>}
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className="grid-col-4">
          <div className="nopad">
            <div className="mar-b-xs">
              {LINKS.map((link, linkIndex) => {
                return (
                  <React.Fragment key={`link_group_child${linkIndex}`}>
                    {link.type === 'text' && <p className='type-base pad-r-xs'>{link.content}</p>}
                    {link.type === 'phone' && <p className='type-base pad-r-xs'>{link.content} <a href={`tel:+1-${link.number}`} className="link-text3 solo type-base phone-link">{link.number}</a> {link.append}</p>}
                    {link.type === 'link' && (link.role === undefined || link.role === role) && <p><a href="/" onClick={(e) => {
                      e.preventDefault()
                      linkClick(link)
                    }} className="font-regular link-text3 solo type-base">{link.content}</a></p>}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpfulLinks