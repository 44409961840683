import { useRef, useState } from 'react';

import { Checkbox } from '@att-bit/duc.components.checkbox';
import { DatePicker } from '@att-bit/duc.components.date-picker';
import { Select } from '@att-bit/duc.components.select';
import { TextField, TextFieldType } from '@att-bit/duc.components.text-field'
import { TextArea } from '@att-bit/duc.components.text-area'
import { ReactGA, GA_EVENTS } from 'util/analytics'

import ReCAPTCHA from 'react-google-recaptcha'
import { COMPLETE } from 'store/contstants';
import { STATES, VALID_EMAIL } from 'store/form';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from 'store/slices/applicationSlice';
import { postData } from 'store/Api'
import Breadcrumbs from 'components/Breadcrumbs';

import './WSCForm.scss'

const CHANNELS = ['AT&T Retail', 'Authorized Retail', 'In-home Experts', 'Property Staff']
const CHANNEL_IGNORABLE = [
  ['dealerName'],
  [],
  ['dealerName'],
  ['vpgmMarket', 'dealerName', 'submitterEmail', 'submitterName', 'submitterPhone', 'submitterAttUid', 'supervisorName', 'supervisorEmail', 'orderNumber', 'confirmOrderNumber', 'customerName', 'customerPhone']
]
const MARKETS = ['Southwest States', 'Gulf States', 'Greater Lakes', 'Northern California', 'North Texas', 'South Texas', 'Southeast States', 'Florida', 'KAMO', 'Mid-ATL States']
const RATE_PLANS = ['5GB ($245)', '2GB ($145)', '1000Mbps ($80)', '500Mbps ($65)', '300Mbps ($55)']

const BASE_FIELDS = ['channel', 'vpgmMarket', 'dealerName', 'submitterEmail', 'submitterName', 'submitterPhone', 'submitterAttUid', 'supervisorName', 'supervisorEmail', 'orderNumber', 'confirmOrderNumber', 'customerName', 'customerPhone', 'propertyName', 'propertyNumber', 'address1', 'address2', 'city', 'state', 'zip', 'ratePlan', 'moveInDate', 'installDate', 'nextAvailable', 'officeHours', 'description']
const OPTIONAL_FIELDS = ['nextAvailable', 'address2']

const WSCForm = () => {
  const recaptchaRef = useRef()
  const { role, roleIndex, categoryIndex, category, subcategory } = useSelector(state => state.application.selection)

  const roles = useSelector(state => state.application.roles)
  const { fields } = categoryIndex > -1 ? roles[roleIndex].categories[categoryIndex] : { fields: ['ticketNumber'] }

  const roleName = roles[roleIndex].name
  const categories = roles[roleIndex].categories
  const categoryName = categories[categoryIndex]?.name

  const DEFAULT_STATE = {}
  BASE_FIELDS.forEach((prop) => {
    DEFAULT_STATE[prop] = { value: '', error: '' }
  })

  const [formData, setFormData] = useState(DEFAULT_STATE)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userData = categoryIndex > -1 ? {
    "requestType": "new",
    "submitterRole": role,
    "submitterRoleName": roleName,
    "category": category,
    "categoryName": categoryName,
    "subcategory": subcategory.subcategory,
  } : {
    "requestType": "existing",
    "submitterRole": role,
    "submitterRoleName": roleName,
  }

  const dispatch = useDispatch()

  const handleFieldChange = (e) => {
    handleInputChange(e.target.name, e.target.value)
  }

  const handleCheckbox = (value) => {
    handleInputChange('nextAvailable', value)
  }

  const handleTextAreaChange = (e) => {
    handleInputChange(e.target.id, e.target.value)
  }

  const handleDateChange = (name, value) => {
    handleInputChange(name, value)
  }

  const handleInputChange = (name, value) => {
    const current = {
      ...formData
    }
    current[name].value = value
    setFormData(current)
  }

  const validateForm = () => {

    const keys = Object.keys(formData)
    let valid = true

    const state = { ...formData }

    const IGNORABLE = getIgnorable()

    keys.forEach((key) => {
      const current = formData[key]
      if (!OPTIONAL_FIELDS.includes(key) && !IGNORABLE.includes(key) ) {

        if (current.value === '' && !(key === 'installDate' && state['nextAvailable'].value === true)) {
          state[key].error =  (key.toLowerCase().includes('date')) ? 'Please enter a date' : 'Field required';
          valid = false
        } else if (key.toLowerCase().includes('email') && !VALID_EMAIL.test(current.value)) {
          state[key].error = 'Invalid email'
          valid = false
        } else if(key === 'confirmOrderNumber' && state[key].value !== state['orderNumber'].value) {
          state[key].error = "Field does not match 'Manual order number'"
          valid = false
        } else {
          state[key].error = ''
        }
      }
    })

    if(recaptchaRef.current.getValue() === '') {
      valid = false;
    }

    setFormData(state)

    return valid

  }

  const getIgnorable = () => {
    if(formData['channel'].value !== '') {
      return CHANNEL_IGNORABLE[formData['channel'].value]
    }
    return []
  }

  const submitForm = async () => {

    if (loading) {
      return
    }

    if (validateForm()) {

      const form = {}

      form['token'] = recaptchaRef.current.getValue()
      form['formType'] = 'wsc'

      const IGNORABLE = getIgnorable()

      Object.keys(formData).forEach((key) => {
        if(!IGNORABLE.includes(key) && !OPTIONAL_FIELDS.includes(key)) {
          switch(key) {
            case 'channel':
              form[key] = CHANNELS[formData[key].value]
            break;
            case 'vpgmMarket':
              form[key] = MARKETS[formData[key].value]
            break;
            case 'ratePlan':
              form[key] = RATE_PLANS[formData[key].value]
            break;
            case 'state':
              form[key] = STATES[formData[key].value].abbreviation
              break;
            case 'moveInDate':
              form[key] = formData[key].value.toLocaleDateString("en-US");
            break;
            case 'installDate':
              form[key] = formData['nextAvailable'].value ? 'Next available' : formData[key].value.toLocaleDateString("en-US");
            break;
            default: 
              form[key] = formData[key].value
          }
        }
      })

      setLoading(true)

      const selectedRole = roles[roleIndex]
      const selectedCategory = categories[categoryIndex]

      postData({
        ...userData,
        ...form,
      }).then((response) => {

        ReactGA.event(GA_EVENTS.acc_form_submitted, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_form_type: userData?.requestType,
          acc_form_city: form['city'],
          acc_form_state: form['state'],
          acc_form_propertyId: form['propertyId'],
          acc_form_propertyName: form['propertyName'],
        })

        setLoading(false)
        dispatch(updateState({
          view: COMPLETE,
          ticket: response.ticket.ticketNumber
        }))

      }).catch(err => {
        
        ReactGA.event(GA_EVENTS.acc_form_invalid, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_error: err.message,
        })
        setErrorMessage(err.message || 'An error occurred, please try again.')
        setLoading(false)

      })

    } else {
      setErrorMessage('Please fix any invalid form fields.')
    }

  }

  return (
    <div className="wcf-form container">

      {categoryIndex > -1 && <div className="row">
        <div className="grid-col mar-t-xs">
          <Breadcrumbs level={subcategory ? 3 : 1} />
        </div>
      </div>}

      <div className="row">
        <div className="grid-col-12 grid-col">
          <div className='mar-b-sm'>
            <h4 className="heading-md mar-b-md">Working Service Conflict (WSC) Form:</h4>
            <p>This form is intended for conflicting service issues where the previous customer at the service address did not disconnect their service (Working Service Conflict). A manual order is required prior to submission of this form.</p>
          </div>
          <h5 className="heading-sm">Supported Scenarios:</h5>
          <div className="list mar-b-sm">
            <ul> 
              <li>New customer moving into residence with a WSC (Working Service Conflict) at service address.</li>
              <li>Back Office Escalation rep must speak to previous customer / landlord / property manager to obtain approval to disconnect service.</li>
            </ul>
          </div>
          <h5 className="heading-sm">Sales Integrity Check:</h5>
          <div className="list mar-b-sm">
            <ul className='mar-b-xs'> 
              <li>Do not knowingly create or contribute to the creation of phantom churn.</li>
              <li>Sellers should not attempt to extend new promotional pricing to existing customers by terminating an existing account and establishing a new one.</li>
              <li>Do not advise a customer to cancel service and establish new service using a different name or variation of their name.</li>
              <li>Follow the outlined customer retention process when customers express a desire to cancel.</li>
              <li>Do not proactively offer to disconnect a customer.</li>
            </ul>
            <p><strong>For more information, please visit the <a href="https://attone--c.vf.force.com/articles/Knowledge/sales-integrity-hub">Sales Integrity Hub</a>.</strong></p>
          </div>

          <h5 className="heading-sm">Must Know:</h5>
          <div className="list mar-b-sm">
            <ul className='mar-b-xs'> 
              <li><strong>Please allow up to 24 hours for resolution</strong>. Submissions after 3pm PST will be worked the following day.</li>
              <li>
                <strong>Until further notice</strong>, conflicts placed Friday thru Sunday (Fri-Sun) will be worked on the next business day. (ex: Conflict submitted Friday evening will be assigned and worked on Monday)
                <ul>
                  <li>Do not call into CRS for status updates. Agents will not be able to assist within the 24-hour commitment.</li>
                </ul>
              </li>
              <li>Please ensure your contact number is accurate. The agent working your submission may reach out for more information if necessary.</li>
              <li>Agents will make 2 attempts to confirm vacancy.</li>
              <li>When a form is rejected, you will receive an email with reason and instructions to resubmit.</li>
              <li>Agents will perform back-office verification, place manual order, and send resolution with install details to the submitter by the e-mail provided.</li>
              <li>The submitter will be notified via the e-mail provided in the order details, <strong>please notify your customer of the installation time and date</strong>.</li>
              <li>A Leasing Agreement or proof of ownership is required to avoid a call to the existing owner which can be uploaded to this form.</li>
              <li>See bottom of form for CRS hours of operation</li>
            </ul>
          </div>
          
          <h4 className="heading-md mar-t-md mar-b-md">Representative Information:</h4>
          
          <div className="pad-b-md-md pad-b-sm-lg pad-b-lg-sm">

            <div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <div className="select-container">
                    <Select
                      id="channel"
                      label="Channel"
                      value={formData["channel"].value}
                      name="channel"
                      disabled={loading}
                      errorMsg={formData["channel"].error !== '' && formData["channel"].error}
                      onChange={({selectedIndex}) => {
                        
                        handleInputChange('channel', selectedIndex)
                      }}
                      >
                      {CHANNELS.map((channel) => <Select.Option key={`channel_${channel}`} value={channel} />)}
                    </Select>
                    </div>
                  </div>
                  {formData['channel'].value !== 3 && <div className="grid-col-6 pad-l-none2 pad-r-none pad-t-none pad-b-none-md pad-b-none-lg">
                    <div className="select-container">
                      <Select
                        id="vpgmMarket"
                        label="VPGM Market"
                        value={formData["vpgmMarket"].value}
                        name="vpgmMarket"
                        disabled={loading}
                        errorMsg={formData["vpgmMarket"].error !== '' && formData["vpgmMarket"].error}
                        onChange={({selectedIndex}) => {
                          handleInputChange('vpgmMarket', selectedIndex)
                        }}
                        >
                        {MARKETS.map((market) => <Select.Option key={`market_${market}`} value={market} />)}
                      </Select>
                    </div>
                  </div>}
                </div>
              </div>

              {formData['channel'].value === 1 && <div className="form-row">
                <TextField
                  id="dealerName"
                  value={formData["dealerName"].value}
                  onChange={handleFieldChange}
                  maxLength={50}
                  name="dealerName"
                  labelText="Dealer company name"
                  disabled={loading}
                  error={formData["dealerName"].error.length > 0}
                  errorText={formData["dealerName"].error}
                  type={TextFieldType.TEXT} />
              </div>}

              {formData['channel'].value !== 3 && <div className='form-row'>

              <div className="form-row">
                <div className="row">
                  <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                    <TextField
                      id="submitterName"
                      value={formData["submitterName"].value}
                      onChange={handleFieldChange}
                      maxLength={30}
                      name="submitterName"
                      disabled={loading}
                      error={formData["submitterName"].error.length > 0}
                      errorText={formData["submitterName"].error}
                      labelText="Submitter name"
                      type={TextFieldType.TEXT} />
                  </div>
                  <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                    <TextField
                      id="submitterPhone"
                      value={formData["submitterPhone"].value}
                      onChange={handleFieldChange}
                      maxLength={20}
                      name="submitterPhone"
                      disabled={loading}
                      error={formData["submitterPhone"].error.length > 0}
                      errorText={formData["submitterPhone"].error}
                      labelText="Submitter phone"
                      type={TextFieldType.TEXT} />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="row">
                  <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                    <TextField
                      id="submitterEmail"
                      value={formData["submitterEmail"].value}
                      onChange={handleFieldChange}
                      maxLength={255}
                      name="submitterEmail"
                      disabled={loading}
                      error={formData["submitterEmail"].error.length > 0}
                      errorText={formData["submitterEmail"].error}
                      labelText="Submitter email"
                      type={TextFieldType.TEXT} />
                  </div>
                  <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                    <TextField
                      id="submitterAttUid"
                      value={formData["submitterAttUid"].value}
                      onChange={handleFieldChange}
                      maxLength={255}
                      name="submitterAttUid"
                      disabled={loading}
                      error={formData["submitterAttUid"].error.length > 0}
                      errorText={formData["submitterAttUid"].error}
                      labelText="Submitter ATTUID"
                      type={TextFieldType.TEXT} />
                  </div>
                </div>
              </div>
            
            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <TextField
                    id="supervisorName"
                    value={formData["supervisorName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="supervisorName"
                    disabled={loading}
                    error={formData["supervisorName"].error.length > 0}
                    errorText={formData["supervisorName"].error}
                    labelText="Supervisor name"
                    type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="supervisorEmail"
                    value={formData["supervisorEmail"].value}
                    onChange={handleFieldChange}
                    maxLength={255}
                    name="supervisorEmail"
                    disabled={loading}
                    error={formData["supervisorEmail"].error.length > 0}
                    errorText={formData["supervisorEmail"].error}
                    labelText="Supervisor email"
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            </div>}

            <div className="form-row">
              <hr />
            </div>

            <h4 className="heading-md mar-t-md mar-b-md">Customer Information:</h4>

            {formData['channel'].value !== 3 && <div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                <TextField
                id="orderNumber"
                value={formData["orderNumber"].value}
                onChange={handleFieldChange}
                maxLength={13}
                name="orderNumber"
                labelText="Manual order number"
                disabled={loading}
                error={formData["orderNumber"].error.length > 0}
                errorText={formData["orderNumber"].error}
                type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                <TextField
                id="confirmOrderNumber"
                value={formData["confirmOrderNumber"].value}
                onChange={handleFieldChange}
                maxLength={13}
                name="confirmOrderNumber"
                labelText="Confirm manual order number"
                disabled={loading}
                error={formData["confirmOrderNumber"].error.length > 0}
                errorText={formData["confirmOrderNumber"].error}
                type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <TextField
                    id="customerName"
                    value={formData["customerName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="customerName"
                    disabled={loading}
                    error={formData["customerName"].error.length > 0}
                    errorText={formData["customerName"].error}
                    labelText="New customer name"
                    type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="customerPhone"
                    value={formData["customerPhone"].value}
                    onChange={handleFieldChange}
                    maxLength={255}
                    name="customerPhone"
                    disabled={loading}
                    error={formData["customerPhone"].error.length > 0}
                    errorText={formData["customerPhone"].error}
                    labelText="New customer phone"
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            </div>}
            

            <div className="form-row">
              <TextField
                id="propertyName"
                value={formData["propertyName"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="propertyName"
                labelText="Realtor/Leasing Office/Mgmt Company name/Landlord name"
                disabled={loading}
                error={formData["propertyName"].error.length > 0}
                errorText={formData["propertyName"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <TextField
                id="propertyNumber"
                value={formData["propertyNumber"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="propertyNumber"
                labelText="Office number"
                disabled={loading}
                error={formData["propertyNumber"].error.length > 0}
                errorText={formData["propertyNumber"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row mar-b-md">
              <hr />
            </div>

            <h5 className="heading-sm">Service Address:</h5>

            <div className="form-row">
              <TextField
                id="address1"
                value={formData["address1"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="address1"
                labelText="Address 1"
                disabled={loading}
                error={formData["address1"].error.length > 0}
                errorText={formData["address1"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <TextField
                id="address2"
                value={formData["address2"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="address2"
                labelText="Address 2"
                disabled={loading}
                error={formData["address2"].error.length > 0}
                errorText={formData["address2"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <div className="row">
                <TextField
                  id="city"
                  value={formData["city"].value}
                  onChange={handleFieldChange}
                  maxLength={30}
                  name="city"
                  labelText="City"
                  disabled={loading}
                  error={formData["city"].error.length > 0}
                  errorText={formData["city"].error}
                  type={TextFieldType.TEXT} />
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="grid-col-7 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <div className="select-container">
                    <Select
                      id="state"
                      label="State"
                      value={formData["state"].value}
                      name="state"
                      disabled={loading}
                      errorMsg={formData["state"].error !== '' && formData["state"].error}
                      onChange={({selectedIndex}) => {
                        handleInputChange('state', selectedIndex)
                      }}
                      >
                      {STATES.map((state) => <Select.Option key={`state_${state.abbreviation}`} value={state.name} />)}
                    </Select>
                  </div>
                </div>
                <div className="grid-col-5 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="zip"
                    value={formData["zip"].value}
                    onChange={handleFieldChange}
                    maxLength={10}
                    name="zip"
                    labelText="ZIP"
                    disabled={loading}
                    error={formData["zip"].error.length > 0}
                    errorText={formData["zip"].error}
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>
            <div className="form-row">
              <hr />
            </div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <div className="select-container">
                    <Select
                      id="ratePlan"
                      label="Rate plan"
                      value={formData["ratePlan"].value}
                      name="ratePlan"
                      disabled={loading}
                      errorMsg={formData["ratePlan"].error !== '' && formData["ratePlan"].error}
                      onChange={(selectState) => {
                        handleInputChange('ratePlan', selectState.selectedIndex)
                      }}
                      >
                      {RATE_PLANS.map((ratePlan) => <Select.Option key={`ratePlan_${ratePlan}`} value={ratePlan} />)}
                    </Select>
                    <div className='list'>
                      <ul>
                        <li><small>Monthly pricing with AutoPay/Paperless Billing and Bank (ACH)/debit card.</small></li>
                        <li><small>There will be a $5 increase in price if customer does not enroll in AP/PB).</small></li>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <label className='formfield-label custom-label'>Move in date</label>
                  <div className='date-picker'>
                  <DatePicker
                    id="moveInDate"
                    errorMsg={formData["moveInDate"].error}
                    selectedDate={formData["moveInDate"].value}
                    placeHolderText='mm/dd/yyyy'
                    onSelect={(value) => handleDateChange('moveInDate',value)}
                    />
                    </div>
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <label className={`formfield-label ${formData['nextAvailable'].value === true ? '': 'custom-label'}`}>Install date</label>
                  {formData['nextAvailable'].value !== true && <div className='date-picker'><DatePicker
                    id="installDate"
                    selectedDate={formData["installDate"].value}
                    placeHolderText='mm/dd/yyyy'
                    onSelect={(value) => handleDateChange('installDate',value)}
                    errorMsg={formData["installDate"].error}
                    /></div>}
                    <div className={`${formData['nextAvailable'].value !== true ? 'mar-t-xxs': ''}`}>
                      <Checkbox
                        id="nextAvailable"
                        name="nextAvailable"
                        text="Earliest Available"
                        checked={formData["nextAvailable"].value}
                        onChange={handleCheckbox}
                        error={formData["nextAvailable"].error.length > 0}
                        errorMessage={formData["nextAvailable"].error}
                      />
                      <p><small>Check this box to select earliest available date after move-in date</small></p>
                    </div>                  
                </div>
              </div>
            </div>
          

            <div className="form-row">
              <TextArea
                id="officeHours"
                input={formData["officeHours"].value}
                name="officeHours"
                disabled={loading}
                error={formData["officeHours"].error.length > 0}
                errorText={formData["officeHours"].error}
                onChange={handleTextAreaChange}
                maxLength={500}
                labelText="Office hours"
                placeHolder="Enter office hours for Realtor/Leasing Office/Mgmt Company to confirm vacancy" />
            </div>
           

            <div className="form-row">
              <TextArea
                id="description"
                input={formData["description"].value}
                name="description"
                disabled={loading}
                error={formData["description"].error.length > 0}
                errorText={formData["description"].error}
                onChange={handleTextAreaChange}
                maxLength={500}
                labelText="Description of issue"
                placeHolder="Please include who, what, when, where & why" />
                <p><small>Please enter the following - 1) Cause of conflict 2) Customer Internet plan requesting (if different) 3) Office hours and availability to confirm vacancy 4) Any additional information such as customer requests afternoon install <strong>(Reminder: CRS agents will only make 2 attempts to confirm vacancy. If unable to confirm, submission will be returned for more information.)</strong>
                </small></p>
            </div>

            </div>

          <div className='small-content pad-t-sm'>
            <div className="list mar-b-sm">
              <p><strong>Hours of operation:</strong></p>
              <ul className='mar-b-xs'> 
                <li>Mon-Sat: 9 a.m. - 11 p.m.</li>
                <li>Sun: 10 a.m. - 10 p.m.</li>
              </ul>
            </div>
            <div className="list mar-b-sm">
              <p><strong>Holidays:</strong></p>
              <ul className='mar-b-xs'> 
                <li>Thanksgiving Day - Closed</li>
                <li>Christmas Eve - Open (10am - 12 a.m. ET)</li>
                <li>Christmas Day - Closed</li>
                <li>New Year's Eve - Open (10am - 12 a.m. ET)</li>
                <li>New Year's Day - Open normal hours</li>
              </ul>
            </div>
          </div>

            <div className="form-row">
              <div className="pad-b-xs">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcFzUMpAAAAAO5TrQaycNzUvwo_bxZvOCDN0GYX"
                />
              </div>
              <hr />
            </div>

            {errorMessage.length > 0 && <div className="form-row">
              <p className="type-sm color-red-600">{errorMessage}</p>
            </div>}

            <div className="form-row">

              {loading === false && <button className='btn-primary' onClick={() => {
                submitForm()
              }}>Submit</button>}              

              {loading === true && <button className="btn-primary flex" type="button" disabled="disabled">
                <svg viewBox="0 0 43 43" focusable="false" className="loading-spinner">
                  <g fill="none">
                    <circle r="20" cx="21.5" cy="21.5" stroke="#878c94" strokeWidth="3" opacity=".25" />
                    <circle r="20" cx="21.5" cy="21.5" className="loading-spinner-outer" strokeDasharray="89,200" stroke="#009fdb" strokeWidth="3" strokeLinecap="round" />
                  </g>
                </svg>
                <span>Sending...</span>
              </button>}

            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default WSCForm