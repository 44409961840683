import { CardshellRow } from '@att-bit/duc.components.cardshell-row'
import { Cardshell, CardShellVariant } from '@att-bit/duc.components.cardshell'
import { Popover, PopoverAlign } from '@att-bit/duc.components.popover'
import { IconSvg, IconSvgSizes } from "@att-bit/duc.components.icon-svg";

import React, { useState } from 'react'

import { ReactGA, GA_EVENTS } from 'util/analytics'

import HeadsetLogo from 'images/reskin/icon-headphone.png'
import HandshakeLogo from 'images/reskin/icon-handshake.png'
import BubbleLogo from 'images/reskin/icon-message.png'
import PhoneIcon from 'images/reskin/icon-city.png'
import CommunityIcon from 'images/reskin/icon-community.png'

import { useSelector } from 'react-redux'

import './Categories.scss'
import { Link, useHistory } from 'react-router-dom';

const Categories = () => {

  const history = useHistory()

  const categories = useSelector(state => {
    const app = state.application
    return app.roles[app.selection.roleIndex].categories
  })

  const { roles } = useSelector(state => state.application)
  const { roleIndex } = useSelector(state => state.application.selection)

  const onSelect = (selectedIndex) => {
    const category = categories[selectedIndex]
    const role = roles[roleIndex]

    ReactGA.event(GA_EVENTS.pageview, {
      page_title: category?.id,
      page_location: `${role?.id}/new/${category?.id}/`
    })

    ReactGA.event(GA_EVENTS.acc_category_selected, {
      acc_role_id: role?.id,
      acc_role_name: role?.name,
      acc_category_id: category?.id,
      acc_category_name: category?.name,
    })

    if (category.path) {
      history.push(category.path)
    } else {
      history.push(`/${role?.slug}/new/${category?.slug}`)
    }
  };

  const getIcon = (iconIndex) => {
    switch (iconIndex) {
      case 1:
        return HandshakeLogo
      case 2:
        return BubbleLogo
      case 3:
        return PhoneIcon
      case 4:
        return CommunityIcon
      default:
        return HeadsetLogo
    }
  }

  const [show, setShow] = useState(false);

  const togglePopover = (showStatus) => {
    setShow(showStatus);
  };

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="26.767" height="11.095" viewBox="0 0 26.767 11.095">
  <g id="Group_111" data-name="Group 111" transform="translate(-418.5 -1086.97)">
    <path id="Path_27" data-name="Path 27" d="M1351.7-1028.882l5.1,5.1-5.1,4.925" transform="translate(-912.596 2116.382)" fill="none" stroke="#2356b2" stroke-width="1.5"/>
    <line id="Line_3" data-name="Line 3" x2="25" transform="translate(418.5 1092.5)" fill="none" stroke="#2356b2" stroke-width="1.5"/>
  </g>
</svg>`

  return (
    <div className="categories container pad-t-md pad-b-md">

      <div className="row">
        <div className="grid-col">
          <div className='flex flex-items-center mar-b-xxs '>
            <h2 className='heading-lg font-regular mar-r-xxs'>{roles[roleIndex]?.name}</h2>
            <Popover
              position="bottom"
              className={""}
              show={show}
              align={PopoverAlign.center}
              smallPadding={true}
              showClose={false}
              scrollOffsetTop={50}
              onToggle={togglePopover}
            >
              <Popover.Trigger>
                <button className='btn-reset link-text3 solo font-regular'><em>Change role</em></button>
              </Popover.Trigger>
              <Popover.Content className={"pad-xs-all"}>
                {roles.map(({ name, id }, index) => {
                  const active = roles[roleIndex].id === id ? 'link-text3 popover-active' : 'link-text'
                  return <div key={`popover_item_${index}`}>
                    {index > 0 && <div className='mar-b-xxs mar-t-xxs'><hr /></div>}
                    <div className=''>
                      <a href="/" onClick={(e) => {
                        e.preventDefault()
                        setShow(false)
                        setTimeout(() => {
                          history.push(`/${roles[index].slug}`)
                        }, 50)
                      }} className={`${active} solo font-regular pad-r-sm`}>
                        {name}
                      </a>
                    </div>
                  </div>
                })}
              </Popover.Content>
            </Popover>
          </div>


          <h3 className='heading-sm font-regular'>How can we help you today?</h3>
        </div>
      </div>
      <div id="cat-scroll"></div>
      <CardshellRow className="pad-t-none pad-b-none">
        {categories.map((cat, index) => {
          return (
            <div key={`cat_${index}`} className="sm-card grid-col-2 pad-b-xs-sm pad-t-none-sm">
              <Cardshell
                variant={CardShellVariant.BUTTON}
                isChecked={false}
                onClick={() => {
                  onSelect(index)
                }}
              >
                <div className="rel text-center text-left-sm flex-sm pad-t-none-sm flex-items-center pad-l-md-sm">
                  <div className="image-wrapper">
                    <img className="icon mar-b-xs" src={getIcon(index)} alt="Store" />
                  </div>
                  <div className='type-sm card-text'>{cat.name}</div>
                </div>
              </Cardshell>
            </div>
          )
        })}
      </CardshellRow>

      <div className="grid-col-12">
        <Link to={`/${roles[roleIndex].slug}/existing`} className="link-text-3 solo font-regular flex flex-items-center">
          <span className='pad-r-xxs'>
            Have an existing case number?
          </span>
          <IconSvg
            size={IconSvgSizes.SM}
            svgNodeStr={svg}
            aria-label="Existing Case Number"
            aria-hidden="false"
          />
        </Link>
      </div>

    </div>
  )
}

export default Categories