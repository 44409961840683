import './Modal.scss'
import { Modal, ModalSize } from '@att-bit/duc.components.modal';
import ModalBackground from 'images/reskin/modal-accent.png'

import { useSelector } from 'react-redux'
import CONSTANTS from 'store/contstants';
import ResolutionForm from 'components/ResolutionForm';
import WSCForm from 'components/WSCForm';
import CompetitorConflictForm from 'components/CompetitorConflictForm';
import Confirmation from 'components/Confirmation';
import Tiles from 'components/Tiles';
import Complete from 'components/Complete';
import Subcatagories from 'components/Subcatagories';
import { useHistory } from 'react-router-dom';

export const ModalFullScreen = () => {

  const history = useHistory()

  const { roles } = useSelector(state => state.application)
  const { roleIndex } = useSelector(state => state.application.selection)

  const view = useSelector((state) => state.application.view);

  const handleClose = () => {
    history.push('/' + roles[roleIndex].slug)
  }



  return (
    <>
      {view !== CONSTANTS.CATEGORIES &&
        < Modal
          onClose={() => handleClose()}
          size={ModalSize.FULLSCREEN}
          aria-modal="true"
          aria-label={'base modal'}
          scrimZindex={20}
        >
          <Modal.Body>
            <div className='modal-body'>
              <div className='absolute modal-background-outer'>
                <img className="modal-background" src={ModalBackground} alt="Modal Accent" />
                <div className='rel pad-sm-all-md'>
                  <div className='container'>
                    <div className="row">
                      {view === CONSTANTS.SUBCATEGORIES && <div className="grid-col-12">
                        <Subcatagories />
                      </div>}
                      <div className="grid-col-10 grid-col-8-lg grid-col-6-xl">
                        {view === CONSTANTS.RESOLUTION && <ResolutionForm />}
                        {view === CONSTANTS.WSCFORM && <WSCForm />}
                        {view === CONSTANTS.CCFORM && <CompetitorConflictForm />}
                        {view === CONSTANTS.TILES && <Tiles />}
                        {view === CONSTANTS.CONFIRMATION && <Confirmation />}
                        {view === CONSTANTS.COMPLETE && <Complete />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal >
      }
    </>
  );
};

export default ModalFullScreen