import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateState } from 'store/slices/applicationSlice'
import { ReactGA, GA_EVENTS } from 'util/analytics'
import { RESOLUTION } from 'store/contstants'

import HeadsetLogo from 'images/reskin/icon-headphone.png'
import HandshakeLogo from 'images/reskin/icon-handshake.png'
import BubbleLogo from 'images/reskin/icon-message.png'
import PhoneIcon from 'images/reskin/icon-city.png'

import { CardshellRow } from '@att-bit/duc.components.cardshell-row'
import { Cardshell, CardShellVariant } from '@att-bit/duc.components.cardshell'



import './Confirmation.scss'
import Breadcrumbs from 'components/Breadcrumbs'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const LINKS = {
  Online: {
    link: 'https://www.att.com/support/',
    linkText: 'AT&T - Customer Care Center',
  },
  App: {
    link: 'https://www.att.com/support/article/u-verse-high-speed-internet/KM1207739/',
    linkText: 'Smart Home Manager',
  }
}

const Confirmation = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const [success, setSuccess] = useState(false)

  const { roles } = useSelector(state => state.application)
  const { categoryIndex, roleIndex, subcategory } = useSelector(state => state.application.selection)

  const selectedRole = roles[roleIndex]

  const categories = useSelector(state => {
    return state.application.roles[roleIndex].categories
  })

  const selectedCategory = categories[categoryIndex]

  const handleYes = () => {
    ReactGA.event(GA_EVENTS.acc_resolved_clicked, {
      acc_role_id: selectedRole?.id,
      acc_role_name: selectedRole?.name,
      acc_category_id: selectedCategory?.id,
      acc_category_name: selectedCategory?.name,
      acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
      acc_subcategory_name: subcategory?.subcategory,
    })

    setSuccess(true)
  }

  const handleNo = () => {
    if (subcategory.alternateEscalationForm === "") {
      ReactGA.event(GA_EVENTS.acc_view_form_clicked, {
        acc_role_id: selectedRole?.id,
        acc_role_name: selectedRole?.name,
        acc_category_id: selectedCategory?.id,
        acc_category_name: selectedCategory?.name,
        acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
        acc_subcategory_name: subcategory?.subcategory,
      })
      dispatch(updateState({
        view: RESOLUTION,
      }))
    } else {
      ReactGA.event(GA_EVENTS.acc_view_external_alternate_form_clicked, {
        acc_role_id: selectedRole?.id,
        acc_role_name: selectedRole?.name,
        acc_category_id: selectedCategory?.id,
        acc_category_name: selectedCategory?.name,
        acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
        acc_subcategory_name: subcategory?.subcategory,
        acc_link_url: subcategory.alternateEscalationForm,
      })
      const newWindow = window.open(subcategory.alternateEscalationForm, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const handleLinkClick = (subcategory, onlyTrack) => {
    ReactGA.event(GA_EVENTS.acc_support_link_clicked, {
      acc_role_id: selectedRole?.id,
      acc_role_name: selectedRole?.name,
      acc_category_id: selectedCategory?.id,
      acc_category_name: selectedCategory?.name,
      acc_link_url: subcategory.link,
      acc_link_name: subcategory.linkText,
    })

    if (!onlyTrack) {
      const newWindow = window.open(subcategory.link, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const onSelect = (cat) => {

  }

  const handleCreateAnotherRequest = () => {
    ReactGA.event(GA_EVENTS.acc_create_another_clicked, {
      acc_role_id: selectedRole?.id,
      acc_role_name: selectedRole?.name,
      acc_category_id: selectedCategory?.id,
      acc_category_name: selectedCategory?.name,
      acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
      acc_subcategory_name: subcategory?.subcategory,
    })

    history.push(`/${selectedRole?.slug}`)
  }


  const getIcon = (iconIndex) => {
    switch (iconIndex) {
      case 1:
        return HandshakeLogo
      case 2:
        return BubbleLogo
      case 3:
        return PhoneIcon
      default:
        return HeadsetLogo
    }
  }



  return (
    <div className="pad-b-xs">
      <div className="container">
        <div className="row">
          <div className="grid-col mar-t-xs">
            <Breadcrumbs />
          </div>
        </div>
        <div className="row">
          <div className="grid-col-12">
            <div className=" confirmation pad-t-xl-sm pad-b-xl-sm pad-t-md-md pad-b-md-md pad-t-md-lg pad-b-md-lg pad-r-xxl pad-l-md pad-r-md">
              <div className='confirmation-content'>
                {subcategory.action === "phone" && <>
                  <p className="mar-b-xs">Great news! We have a team able to help with this escalation.</p>
                  <div className="font-bold line-h-tight">Please call <a href={`tel:+${subcategory.phone}`} className="link-text">{subcategory.phone}</a> to receive immediate assistance.</div>
                </>}

                {subcategory.action === "link" && <>
                  <p className="mar-b-xxs">Visit the following link to help with this escalation.</p>
                  <div className="font-bold mar-b-md-sm mar-b-sm-md mar-b-sm-lg">
                    <a href="/" onClick={(e) => {
                      e.preventDefault()
                      handleLinkClick(subcategory)
                    }} target="_blank" rel="noreferrer" className="link-text3 font-regular solo type-sm">{subcategory.linkText}</a>
                  </div>
                </>}

                {subcategory.action === "email link" && <>
                  <p className="mar-b-xxs">Great news! We have a team able to help with this escalation.</p>
                  <div className="type-sm">
                    Please email <a href={`mailto:${subcategory.email}`} onClick={(e) => {
                      handleLinkClick(subcategory, true)
                    }} className="link-text3 font-regular solo type-sm">{subcategory.linkText}</a> ({subcategory.email}) for support.
                  </div>
                </>}

                {subcategory.action === "email form" && <>
                  <p className="mar-b-xxs">Great news! We have a team able to help with this escalation.</p>
                  <div className="font-bold mar-b-xs">
                    <a href="/" onClick={(e) => {
                      e.preventDefault()
                      handleLinkClick(subcategory)
                    }} target="_blank" rel="noreferrer" className="link-text3 font-regular solo type-sm">{subcategory.linkText}</a>
                  </div>
                  <div className="type-sm">Please email completed form to <a href={`mailto:${subcategory.email}`}>{subcategory.email}</a></div>
                </>}

                {subcategory.action === "phone and link" && <>
                  <p className="mar-b-xxs">Great news! We have a team able to help with this escalation.</p>
                  <div className="font-bold mar-b-xs">
                    <a href="/" onClick={(e) => {
                      e.preventDefault()
                      handleLinkClick(subcategory)
                    }} className="link-text3 font-regular solo type-sm">{subcategory.linkText}</a>
                  </div>
                  <p className="type-sm font-bold line-h-tight ">Please call <a href={`tel:${subcategory.phone}`}>{subcategory.phone}</a> to receive immediate assistance.</p>
                </>}


                {subcategory.action === "tiles" && <>
                  <p className="mar-b-xxs">Great news! We have a team able to help with this escalation.</p>
                  <CardshellRow className="pad-t-none pad-b-none">
                    {subcategory.tiles.map((cat, index) => {
                      return (
                        <div key={`cat_${index}`} className="sm-card grid-col-2 pad-b-xs-sm pad-t-none-sm">
                          <Cardshell
                            variant={CardShellVariant.BUTTON}
                            isChecked={false}
                            onClick={() => {
                              onSelect(index)
                            }}
                          >
                            <div className="rel text-center text-left-sm flex-sm pad-t-none-sm flex-items-center pad-l-md-sm">
                              <div className="image-wrapper">
                                <img className="icon mar-b-xs" src={getIcon(index)} alt="Store" />
                              </div>
                              <div className='type-sm card-text'>{cat.name}</div>
                            </div>
                          </Cardshell>
                        </div>
                      )
                    })}
                  </CardshellRow>
                </>}

                {subcategory.action === "message" && <>
                  <p className="font-bold mar-b-xs">{subcategory.message}</p>
                </>}

              </div>

              {subcategory.hideOnlineSupport !== "yes" && <>
                <div className='mar-t-sm mar-b-xs'>
                  <hr />
                </div>
                <p className="type-sm">Prefer online support? We have two options for that:</p>
                <p className='type-sm mar-t-xxxs'>Online: <a href="/" onClick={(e) => {
                  e.preventDefault()
                  handleLinkClick(LINKS.Online)
                }} className="link-text3 font-regular solo type-sm">{LINKS.Online.linkText}</a></p>
                <p className='type-sm mar-t-xxxs'>App: <a href="/" onClick={(e) => {
                  e.preventDefault()
                  handleLinkClick(LINKS.App)
                }} className="link-text3 font-regular solo type-sm">{LINKS.App.linkText}</a></p>
              </>}
            </div>
          </div>
        </div>

        <div className="row mar-b-md-sm mar-b-sm-md mar-b-sm-lg mar-t-sm-sm">

          {success === false && subcategory.hideIssueResolution !== "yes" && <div className="grid-col">
            <div className="modal-button-container flex flex-items-center">
              <h4 className="type-sm font-regularå">Did this help you resolve your issue?</h4>
              <div className='flex flex-items-center'>
                <button className="btn-primary btn-small mar-l-md mar-l-none-sm" onClick={() => handleYes()}>Yes</button>
                <button className="btn-primary btn-small mar-l-xs" onClick={() => handleNo()}>No</button>
              </div>
            </div>
          </div>}

          {success === true && <div className="grid-col">
            <div className="modal-button-container flex flex-items-center">
              <h4 className="type-sm font-regular">Great! Thank you for using AT&amp;T Connected Communities.</h4>
              <div>
                <button className="btn-primary mar-l-xs mar-l-none-sm" onClick={() => handleCreateAnotherRequest()}>Create another request</button>
              </div>
            </div>
          </div>}

        </div>

      </div>
    </div>
  )
}

export default Confirmation