import React, {
  // CSSProperties,
  useRef,
  useState,
  useEffect,
} from "react";
import { useSwipeable } from "react-swipeable";
// import { KeyPress } from "@att-bit/duc.utils.key-press";
import { useWindowSize } from "@att-bit/duc.hooks.use-window-size";
import { IconSvg, IconSvgSizes } from "@att-bit/duc.components.icon-svg";

import './Carousel.scss'

export type CarouselV2Props = {
  /**  children (text/jsx) to be rendered in component  */
  children: React.ReactNode;
  /** if the app wants to set a minimum card width for desktop. If the calculated cardWidth is lower
   *  this cardMinWidth, one card is dropped. Default cardMinwidth is 125px */
  cardMinWidth?: number;
  /** Number of cards visible on the page */
  cardsVisible?: number;
  /** to have the card edge to edge on Mobile */
  mobileFullWidth?: boolean;
  /** to turn on the opacity to 1 all the cards on mobile viewport, if false, only the active card will 
  have opacity 1 and rest of the cards will have .7. */
  fullOpacity?: boolean;
  /** Mobile and tablet can have the first card centered */
  mobileTabOffset?: boolean;
  /** containerID is used to uniquely identify a benfits panel */
  containerID?: string;
  /** autoScroll is a duration indicating how frequently (in milliseconds) the carousel should scroll on it's own */
  autoScroll?: number;
};

export enum cardSpacing {
  DESKTOP = 24,
  TABLET = 16,
  MOBILE = 12,
}

// for memo: export const Carousel = React.memo(({
export const CarouselV2 = ({
  children,
  cardsVisible = 1,
  cardMinWidth = 125,
  mobileFullWidth = false,
  fullOpacity = false,
  mobileTabOffset = true,
  autoScroll = 0,
  ...props
}: CarouselV2Props) => {
  // console.log("RENDER CYCLE");

  let leftOffset: number = 0;
  let cardWidth: number = 0;
  const testIdFirst =
    (props["data-testid"] ? props["data-testid"] + "_" : "") + "spanMsgLeft";
  const testIdSecond =
    (props["data-testid"] ? props["data-testid"] + "_" : "") + "spanMsgRight";

  /* useRef */
  const carouselRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef([]);
  const windowSize = useWindowSize();

  // HOOKS ##############################################################
  let totalNoOfCards = React.Children.count(children);
  const [numCards, setNumCards] = useState(cardsVisible);
  // eslint-disable-next-line
  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);
  const [visibleStart, setVisibleStart] = useState(1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // console.log("first numcards: ", numCards);
  const isMobile = windowSize.mobile;
  const isTablet = windowSize.tablet;
  let gridColClass = "";

  useEffect(() => {
    // console.log("useEffect 1 ");
    updateParameters();
    updateCardWidth();
    // eslint-disable-next-line
  }, [windowSize.viewport]);

  useEffect(() => {
    if (!carouselRef) {
      return;
    } else {
      // console.log("useEffect 2 if numCards changed");
      findGridColClass();
    }
    // eslint-disable-next-line
  }, [numCards]);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleScrollChange(activeSlideIndex),
    onSwipedRight: () => handleScrollChange(activeSlideIndex),
  });

  let initialPositionX = null;
  // let moving = false;
  let currentPositionX = null;

  let currentPositionY = null;
  let initialPositionY = null;

  const gestureStart = (e) => {
    var touchobj = e.changedTouches;
    initialPositionX = touchobj[0].screenX;
    initialPositionY = touchobj[0].screenY;
    // moving = true;
  };
  const gestureEnd = (e) => {
    // moving = false;
    var touchobj = e.changedTouches;
    currentPositionX = touchobj[0].screenX;
    currentPositionY = touchobj[0].screenY;
    touchMouseAction();
  };
  const touchMouseAction = () => {
    // moving = false;
    // let scroll = false;
    let diffX = currentPositionX - initialPositionX;
    let diffY = currentPositionY - initialPositionY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 0) {
        // console.log("moving right ");
        if ((isMobile || isTablet) && visibleStart === 1) {
          // first card
          // console.log("first card");
        } else {
          animateCarousel(undefined, "prev");
        }
      } else {
        // console.log("moving left ");
        // console.log(
        //   "activeSlideIndex: ",
        //   activeSlideIndex,
        //   ",maxSlides: ",
        //   maxSlides
        // );
        if ((isMobile || isTablet) && activeSlideIndex === maxSlides) {
          // last card
          // console.log("last card");
          //  if (activeSlideIndex < React.Children.count(children)-1) {
          //   setActiveSlideIndex(activeSlideIndex + 1);
          //  }
        } else {
          animateCarousel(undefined, "next");
        }
      }
    }
  };

  // const handleKeyDown = (e: any) => {
  //   if (KeyPress.tabIsPressed(e)) {
  //     e.stopPropagation();
  //     let el = KeyPress.shiftIsPressed(e)
  //       ? e.target.previousElementSibling
  //         ? e.target.previousElementSibling
  //         : e.target
  //       : e.target.nextElementSibling
  //       ? e.target.nextElementSibling
  //       : e.target;
  //     while (el && !el.hasAttribute("data-carousel-card-index")) {
  //       el = el.parentElement;
  //     }
  //     let elIndex = Number(el.getAttribute("data-carousel-card-index") - 1);
  //     if (!KeyPress.shiftIsPressed(e) && !e.target.nextElementSibling) {
  //       elIndex = elIndex + 1;
  //     }
  //   }
  // };

  useEffect(
    () => {
      let timer = null
      if (autoScroll){
        timer = setTimeout(() => {
          if (activeSlideIndex >= totalNoOfCards - 1){
            handleScrollChange(0);  // loop
          }
          else {
            animateCarousel(undefined, "next");
          }
        }, autoScroll);
      }

      // Clear autoscroll timout
      return () => {
        if (timer) clearTimeout(timer);
      };
    },
    [autoScroll, activeSlideIndex, numCards]
  );


  const updateParameters = () => {
    /* istanbul ignore else */
    if (!carouselRef) {
      return;
    }
    totalNoOfCards = React.Children.count(children);
    maxSlides = Math.floor((totalNoOfCards - 1) / numCards);
  };

  // const resetVisibleStart = () => {
  //   /* istanbul ignore else */
  //   if (!carouselRef) {
  //     return;
  //   }
  //   setVisibleStart(1);
  //   setActiveSlideIndex(0);
  // };

  const findGridColClass = () => {
    let gridColNo = 12 / numCards;
    if (gridColNo === 2.4) {
      gridColClass = "grid-col-2-5";
    } else {
      gridColClass = "grid-col-" + gridColNo;
    }
  };

  const updateCardWidth = () => {
    /* istanbul ignore */
    if (!carouselRef) {
      return;
    }
    let tempNumCards: number;

    cardWidth = cardRef?.current[1]?.offsetWidth;
    const visibleArea: number = carouselRef?.current?.offsetWidth;
    // console.log(
    //   "in updatecardwidth 1 mobilebleed: ",
    //   mobileFullWidth,
    //   "isMobile",
    //   isMobile
    // );

    if (mobileFullWidth && isMobile && visibleArea <= 336) {
      cardWidth = cardMinWidth ? cardMinWidth : 336;
      tempNumCards = 1;
    }

    //if the card width goes below the minimum card width then, display one card less to avoid too much shrinking
    if (cardWidth < cardMinWidth && windowSize.desktop) {
      tempNumCards = cardsVisible - 1 > 0 ? cardsVisible - 1 : cardsVisible;
      // console.log(
      //   "in updatecardwidth 2 tempNumCards: ",
      //   tempNumCards,
      //   "cardMinWidth",
      //   cardMinWidth,
      //   "cardWidth: ",
      //   cardWidth
      // );
    } else if (isMobile) {
      //maximum cards for mobile is 1
      tempNumCards = cardsVisible >= 1 ? 1 : cardsVisible;
    } else if (isTablet) {
      //maximum cards for tablet is 2
      tempNumCards = cardsVisible >= 2 ? 2 : cardsVisible;
    } else {
      tempNumCards = cardsVisible;
    }
    setNumCards(tempNumCards);

    //  maxSlides = (Math.floor((totalNoOfCards - 1) / tempNumCards));
    // console.log(
    //   "in updateCardWidth 3 cardWidth: ",
    //   cardWidth,
    //   "numcards: ",
    //   numCards
    // );
  };

  const renderCarouselButtons = (which: string) => {
    const classes = ["carousel-navigation", "carousel-nav"].join(" ");

    return (
      <div className={classes} aria-label={"carousel buttons"}>
        {which === "prev" ? (
          <>
            <span
              id={testIdFirst + "_messageFirst"}
              tabIndex={visibleStart === 1 ? -1 : 0}
              role={visibleStart === 1 ? "none" : "alert"}
              aria-hidden={visibleStart === 1 ? true : false}
              aria-labelledby={testIdFirst}
            >
              <div className={"hidden-spoken"} id={testIdFirst}>
                You have reached the beginning. Disabling Previous button.
              </div>
            </span>
            <button
              value="prev"
              aria-label={"Scroll Left"}
              // {...(visibleStart === 1
              //   ? { ["aria-labelledby"]: testIdFirst + "_messageFirst" }
              //   : {})}
              onClick={animateCarousel}
              disabled={visibleStart === 1}
              className={
                "carousel-control btn-reset touch-space"
              }
            >
              <span
                className={[
                  "inline-flex flex-centered round width-xl-all height-xl-all rotate180",
                ].join(" ")}
              >
                <IconSvg
                  svgNodeStr={`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path class="svg-base" d="M12.27 26.71l-1.41-1.42L20.15 16l-9.29-9.29 1.41-1.42L23 16z"/></svg>`}
                  size={IconSvgSizes.MD}
                />
              </span>
            </button>
          </>
        ) : (
          <>
            <button
              className={[
                "carousel-control btn-reset touch-space"
              ].join(" ")}
              value="next"
              aria-label={"Scroll Right"}
              // {...(visibleStart === maxSlides + 1
              //   ? { ["aria-labelledby"]: testIdSecond + "_messageLast" }
              //   : {})}
              role={visibleStart === maxSlides ? "none" : "none"}
              onClick={animateCarousel}
              disabled={rightDisabled}
            >
              <span
                className={[
                  "inline-flex flex-centered round width-xl-all height-xl-all",
                ].join(" ")}
              >
                <IconSvg
                  svgNodeStr={`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path class="svg-base" d="M12.27 26.71l-1.41-1.42L20.15 16l-9.29-9.29 1.41-1.42L23 16z"/></svg>`}
                  size={IconSvgSizes.MD}
                />
              </span>
            </button>
            <span
              id={testIdSecond + "_messageLast"}
              tabIndex={visibleStart < maxSlides + 1 ? 0 : -1}
              role={visibleStart < maxSlides + 1 ? "alert" : "none"}
              aria-hidden={visibleStart < maxSlides + 1 ? false : true}
              aria-labelledby={testIdSecond}
            >
              <div className={"hidden-spoken"} id={testIdSecond}>
                You have reached the end. Disabling Next button.
              </div>
            </span>
          </>
        )}
      </div>
    );
  };

  const animateCarousel = (
    e?: React.FormEvent<HTMLButtonElement>,
    swipeDirection?: string
  ) => {
    /* istanbul ignore else */
    if (!carouselRef) {
      return;
    }

    //totalNoOfCards = React.Children.count(children);
    const cardWidth = cardRef?.current[1]?.offsetWidth;
    updateParameters();
    let rightHiddenCards = totalNoOfCards - (visibleStart + numCards - 1);
    let leftHiddenCards = visibleStart - 1;
    let x = 0;
    let tempVisibleStart = 1;
    let direction = undefined;
    let tempActiveSlideIndex;
    let storedActiveSlideIndex = activeSlideIndex;

    if (e === undefined) {
      if (swipeDirection !== undefined) {
        direction = swipeDirection;
      } else {
        //carwidth might have changed by resizing the window.
        tempVisibleStart = visibleStart - numCards;
        x = tempVisibleStart * cardWidth + leftOffset;
      }
    } else {
      direction = e.currentTarget?.value;
    }
    if (direction !== undefined) {
      switch (direction) {
        case "prev":
          if (leftHiddenCards >= numCards) {
            tempVisibleStart = visibleStart - numCards;
          } else {
            tempVisibleStart = visibleStart - leftHiddenCards;
          }
          setVisibleStart(tempVisibleStart);
          x = (tempVisibleStart - 1) * cardWidth + leftOffset;
          tempActiveSlideIndex = activeSlideIndex - 1;
          setActiveSlideIndex(tempActiveSlideIndex);
          break;
        case "next":
          if (rightHiddenCards >= numCards) {
            tempVisibleStart = visibleStart + numCards;
          } else {
            tempVisibleStart = visibleStart + rightHiddenCards;
          }
          setVisibleStart(tempVisibleStart);
          x = (tempVisibleStart - 1) * cardWidth + leftOffset;
          if (activeSlideIndex < totalNoOfCards - 1) {
            tempActiveSlideIndex = activeSlideIndex + 1;
            setActiveSlideIndex(tempActiveSlideIndex);
          }
          break;
      }

      setLeftDisabled(tempVisibleStart === 1);
      setRightDisabled(tempVisibleStart === totalNoOfCards - numCards + 1);
    }
    let newX = x;
    let offset;
    if (isMobile || isTablet) {
      if (storedActiveSlideIndex < maxSlides && rightHiddenCards < numCards) {
        offset = (carouselRef.current.offsetWidth - cardWidth * numCards) / 2;
        newX =
          mobileTabOffsetValue +
          cardWidth * (tempVisibleStart - rightHiddenCards) -
          offset;
      } else {
        offset = (carouselRef.current.offsetWidth - cardWidth * numCards) / 2;
        newX =
          mobileTabOffsetValue +
          cardWidth * numCards * tempActiveSlideIndex -
          offset;
      }
    }
    carouselRef.current.style.transform = `translateX(${-newX}px)`;
  };

  const handleScrollChange = (slide: number) => {
    /* istanbul ignore else */
    if (!carouselRef) {
      return;
    }
    updateParameters();
    let x: number;
    let tempVisibleStart: number;
    //totalNoOfCards = React.Children.count(children);
    /* istanbul ignore else */
    if (!carouselRef) {
      return;
    }

    setActiveSlideIndex(slide);
    const cardWidth = cardRef?.current[1]?.offsetWidth;
    if (slide === maxSlides) {
      tempVisibleStart = totalNoOfCards - numCards + 1;
      setVisibleStart(tempVisibleStart);
    } else {
      tempVisibleStart = slide * numCards + 1;
      setVisibleStart(tempVisibleStart);
    }
    setLeftDisabled(tempVisibleStart === 1);
    setRightDisabled(tempVisibleStart === totalNoOfCards - numCards + 1);

    x = (tempVisibleStart - 1) * cardWidth;

    let newX = x;
    let offset;
    if (isMobile || isTablet) {
      if (slide === maxSlides) {
        offset = (carouselRef.current.offsetWidth - cardWidth * numCards) / 2;
        newX =
          mobileTabOffsetValue + cardWidth * (tempVisibleStart - 1) - offset;
      } else {
        offset = (carouselRef.current.offsetWidth - cardWidth * numCards) / 2;
        newX = mobileTabOffsetValue + cardWidth * numCards * slide - offset;
      }
    }
    // console.log(
    //   "************************* After handleScrollChange*****************************"
    // );
    // console.log("in handleScrollChange: totalNoOfCards: ", totalNoOfCards);
    // console.log("in handleScrollChange: slide: ", slide);
    // console.log("in handleScrollChange: activeSlideIndex: ", activeSlideIndex);
    // console.log(
    //   "in handleScrollChange: cardWidth: ",
    //   cardWidth,
    //   "area: ",
    //   carouselRef.current.offsetWidth,
    //   " offset: ",
    //   offset
    // );
    // console.log("in handleScrollChange: MaxSlides: ", maxSlides);
    // console.log("in handleScrollChange: visibleStart: ", visibleStart);
    // console.log("in handleScrollChange: tempVisibleStart: ", tempVisibleStart);
    // console.log("in handleScrollChange: x: ", x);
    // console.log("in handleScrollChangeafter breaking");
    // console.log("in handleScrollChange moving from: ", x, " new x: ", newX);

    carouselRef.current.style.transform = `translateX(${-newX}px)`;
  };

  //numCards = updateCardWidth();
  findGridColClass();
  let maxSlides = Math.floor((totalNoOfCards - 1) / numCards);
  let noButton = totalNoOfCards <= numCards ? true : false;
  let showScrollBar = totalNoOfCards <= numCards ? false : true;
  let mobileTabOffsetValue = 10;
  if (mobileTabOffset) {
    mobileTabOffsetValue = 25;
  }

  const containerTestID = 1;

  // RENDER ##############################################################
  return (
    <>
      <div
        data-testid={props["data-testid"] ? props["data-testid"] : containerTestID }
        className={`custom-carousel carousel container ${showScrollBar === true ? "pad-b-md" : ""}`}
      >
        {/* <div className=" container"> */}
        {Array.from({ length: maxSlides + 1 }).map((_, i) => (
          <input
            key={i}
            className={`carousel-activator ${
              i === activeSlideIndex ? "active " : ""
            }`}
            type="radio"
            name="carousel1up1"
            id={`A${i + 1}-${props["data-testid"] ? props["data-testid"] : containerTestID }`}
            aria-hidden="true"
            tabIndex={-1}
          />
        ))}

        {showScrollBar === true && (
          <div className={"carousel-indicators"}>
            <div
              className={"carousel-indicator"}
            >
              {" "}
              {Array.from({ length: maxSlides + 1 }).map((_, i) => (
                <label
                  key={i}
                  className={` ${i === activeSlideIndex ? "active " : ""} `}
                  onClick={() => {
                    handleScrollChange(i);
                  }}
                  htmlFor={`A${i + 1}-${props["data-testid"] ? props["data-testid"] : containerTestID }`}
                  tabIndex={-1}
                >
                  <span className="hidden-spoken">page {i}</span>
                </label>
              ))}
            </div>
          </div>
        )}
        {/* {!state.isMobile && !state.isMinscreensize && renderCarouselButtons()}
         */}
        <div className={`row-nowrap flex-items-center carousel-overflow `}>
          {!isMobile && !isTablet && (
            <div
              className={`nav-prev grid-col-1 carousel-nav-container text-center ${
                noButton ? "carousel-nav-container-noButton" : ""
              } `}
            >
              {renderCarouselButtons("prev")}
            </div>
          )}
          <div
            className={`grid-col-12 devicePadding carousel-track-container pad-t-xs
                ${mobileFullWidth ? mobileFullWidth : ""}`}
          >
            <div
              className={`row-nowrap carousel-track ${
                noButton
                  ? totalNoOfCards <= 1
                    ? "mar-r-md justify-center"
                    : "justify-center"
                  : " "
              }`}
              ref={carouselRef}
              onTouchStart={gestureStart}
              onTouchEnd={gestureEnd}
            >
              {React.Children.map(children, (child, idx) => {
                if (React.isValidElement(child)) {
                  return (
                    <div
                      {...handlers}
                      className={`${gridColClass} carousel-item ${
                        idx === activeSlideIndex && isMobile ? "active " : ""
                      }  ${fullOpacity ? "allActive " : ""}`}
                      data-carousel-card-index={idx + 1}
                      ref={(element) => (cardRef.current[idx] = element)}
                    >
                      {" "}
                      <div className="carousel-slide height-full">{child}</div>
                    </div>
                  );
                }
              })}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
          {!isMobile && !isTablet && (
            <div
              className={`nav-next grid-col-1 carousel-nav-container text-center ${
                noButton ? "carousel-nav-container-noButton" : ""
              } `}
            >
              {renderCarouselButtons("next")}
            </div>
          )}
        </div>
      </div>

      {/*place static style jsx below */}
      <style jsx global>{`
        :is(.theme-dark-accent-bg, .theme-dark-bg-img) .carousel.carousel {
          --indicator-dot: #fff;
          --indicator-ring: #fff;
        }

        .carousel-control.btn-reset > span,
        .carousel-control.btn-reset:not(.btn-search) > span {
          border: 1px solid #0057b8 !important;
          color: #0057b8;
        }

        .theme-dark-accent-bg .carousel-control.btn-reset > span,
        .theme-dark-bg-img .carousel-control.btn-reset > span {
          border: 1px solid white !important;
          color: white;
        }

        .carousel-control.btn-reset:disabled > span,
        .theme-dark-accent-bg .carousel-control.btn-reset:disabled > span,
        .theme-dark-bg-img .carousel-control.btn-reset:disabled > span {
          border-color: #878c94 !important;
          color: #878c94 !important;
        }
        
      `}</style>
      <style jsx>{`
        //Desktop
        .carousel {
          position: relative;
          list-style: none;
          // padding-bottom: 50px;
          --indicator-dot: #878c94;
          --indicator-ring: #0057b8;
        }

        .carousel-track-container {
          overflow: hidden;
          justify-content: center;
          display: flex;
        }

        .carousel-track {
          display: flex;
          position: relative;
          padding: 0;
          transform: translateX(0%);
          transition: transform 0.7s ease 0s;
        }

        .carousel-slide {
          align-items: stretch;
          display: flex;
          padding: 0;
        }

        .devicePadding {
          padding-left: 0;
          padding-right: 0;
          padding-top: 8px;
        }

        /* carousel indicators */
        .carousel-activator {
          top: 50%;
          opacity: 0;
          position: absolute;
          z-index: -1;
        }

        .carousel-activator {
          left: calc(50% - 6.5px);
          top: calc(50% - 39px);
        }

        .carousel-indicators {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
        }
        
        .carousel-indicator > label {
          display: inline-block;
          border-radius: 50%;
          background-color: transparent;
          margin: 0 4px;
          height: 16px;
          width: 16px;
          position: relative;
          z-index: 2;
        }
        
        .carousel-indicator > label:after {
          content: "";
          background-color: var(--indicator-dot, #878c94);
          border-radius: 100%;
          height: 8px;
          width: 8px;
          position: absolute;
          top: 4px;
          left: 4px;
        }

        .carousel-indicator > label:not(.active) {
          box-shadow: none;
        }

        .carousel-indicator > label.active {
          box-shadow: 0 0 0 1px var(--indicator-ring, #0057b8) inset;
        }
        
      //   .carousel-indicator:hover {
      //     opacity: 0.75;
      //  }

      .carousel-controls {
        display: none;
      }         

        /* carousel controls */
        .carousel-control.btn-reset > span {
          height: 64px;
          width: 64px;
          position: relative;
          cursor: pointer;
          z-index: 3;
          border-color: var(--carousel-control-border-color, #0057b8);
          color: var(--carousel-control-color, #0057b8);
        }

        .carousel-control.btn-reset:disabled > span {
          border-color: #bdc2c7 !important;
          color: #bdc2c7;
        }

        .carousel-control-backward:after {
          transform: rotate(-180deg);
          margin-left: -6px;
        }

        .carousel-control-forward:after {
          transform: rotate(0deg);
          margin-left: -3px;
        }

        .carousel-nav-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }

        .carousel-nav-container-noButton {
          visibility: hidden;
        }

        .carousel .carousel-track .carousel-item {
          padding-right: ${cardSpacing.DESKTOP / 2}px;
          padding-left: ${cardSpacing.DESKTOP / 2}px;
        }
        // .carousel .carousel-track .carousel-item { flex-basis: calc(100vw / ${numCards});
        //                                                max-width: calc(100vw / ${numCards})
        // }

        @media only screen and (max-width: 1024px) {
          .carousel {
            padding: 0 0 50 0;
          }
          .carousel.container {
            padding: 0 0 30px 0;
            max-width: 100%;
          }
          .carousel-nav-container {
            display: none;
          }
          .carousel-track-container {
            flex-basis: 100%;
            max-width: 100%;
            overflow: hidden;
            justify-content: center;
            display: flex;
          }
          .carousel-overflow {
            padding: 0;
            justify-content: center;
          }

          .devicePadding {
            padding-left: 0;
            padding-right: 0;
            padding-top: 8px;
          }

          // [data-carousel-card-index]:not(.active){
          //   opacity: .7;
          //   transition: opacity .7s ease .3s;
          // }

          [data-carousel-card-index].active {
            opacity: 1;
            transition: opacity 0.7s ease 0.3s;
          }

          [data-carousel-card-index].allActive {
            opacity: 1;
            transition: opacity 0.7s ease 0.3s;
          }

          .carousel-item:nth-of-type(1) {
            margin-inline-start: ${mobileTabOffsetValue}px;
          }

          /* peekabo - effect */
          .carousel-track {
           -webkit-overflow-scrolling: touch;
          }
          .carousel .container {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
          }

          .carousel .carousel-track .carousel-item {
            flex-basis: calc(0.85 * 100vw / ${numCards}) !important;
            max-width: calc(0.85 * 100vw / ${numCards}) !important;
            padding-right: ${cardSpacing.TABLET / 2}px;
            padding-left: ${cardSpacing.TABLET / 2}px;
          }
        }

        /* peekabo - effect */
        @media (max-width: 767px) {
          .carousel-track > * {
            // margin: 0;
            -webkit-overflow-scrolling: touch;
          }

          .carousel .carousel-track .carousel-item {
            flex-basis: calc(0.85 * 100vw / ${numCards}) !important;
            max-width: calc(0.85 * 100vw / ${numCards}) !important;
            padding-right: ${cardSpacing.MOBILE / 2}px;
            padding-left: ${cardSpacing.MOBILE / 2}px;
          }
          [data-carousel-card-index]:not(.active) {
            opacity: 0.7;
            transition: opacity 0.7s ease 0.3s;
          }
        }

        @media only screen and (max-width: 336px) {
          .mobileFullWidth {
            padding-right: 0;
            padding-left: 0;
          }
        }
      `}</style>
    </>
  );
  //}
};
