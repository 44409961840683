import { useRef, useState } from 'react';

import { Checkbox } from '@att-bit/duc.components.checkbox';
import { DatePicker } from '@att-bit/duc.components.date-picker';
import { Select } from '@att-bit/duc.components.select';
import { TextField, TextFieldType } from '@att-bit/duc.components.text-field'
import { TextArea } from '@att-bit/duc.components.text-area'
import { ReactGA, GA_EVENTS } from 'util/analytics'

import ReCAPTCHA from 'react-google-recaptcha'
import { COMPLETE } from 'store/contstants';
import { STATES, VALID_EMAIL } from 'store/form';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from 'store/slices/applicationSlice';
import { postData } from 'store/Api'
import Breadcrumbs from 'components/Breadcrumbs';

import './CompetitorConflictForm.scss'


const BASE_FIELDS = ['propertyName','propertyId', 'submitterEmail', 'submitterFirstName', 'submitterLastName', 'submitterPhone', 'address1', 'address2', 'city', 'state', 'zip', 'conflictName', 'conflictTitle', 'conflictDate', 'conflictDescription']
const OPTIONAL_FIELDS = ['address2', 'competitorName', 'competitorType', 'competitorDate', 'competitorDocuments']

const CompetitorConflictForm = () => {
  const recaptchaRef = useRef()
  const { role, roleIndex, categoryIndex, category, subcategory } = useSelector(state => state.application.selection)

  const roles = useSelector(state => state.application.roles)
  const { fields } = categoryIndex > -1 ? roles[roleIndex].categories[categoryIndex] : { fields: ['ticketNumber'] }

  const roleName = roles[roleIndex].name
  const categories = roles[roleIndex].categories
  const categoryName = categories[categoryIndex]?.name

  const DEFAULT_STATE = {}
  BASE_FIELDS.concat(OPTIONAL_FIELDS).forEach((prop) => {
    DEFAULT_STATE[prop] = { value: '', error: '' }
  })

  const FILE_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf",
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/tiff",
    "image/webp"
  ]

  const [currentFiles, setCurrentFiles] = useState(null)
  const [fileError, setFileError] = useState('')
  const [formData, setFormData] = useState(DEFAULT_STATE)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userData = categoryIndex > -1 ? {
    "requestType": "new",
    "submitterRole": role,
    "submitterRoleName": roleName,
    "category": category,
    "categoryName": categoryName,
    "subcategory": subcategory.subcategory,
  } : {
    "requestType": "existing",
    "submitterRole": role,
    "submitterRoleName": roleName,
  }

  const dispatch = useDispatch()

  const handleFieldChange = (e) => {
    handleInputChange(e.target.name, e.target.value)
  }

  const handleTextAreaChange = (e) => {
    handleInputChange(e.target.id, e.target.value)
  }

  const handleDateChange = (name, value) => {
    handleInputChange(name, value)
  }

  const handleInputChange = (name, value) => {
    const current = {
      ...formData
    }
    current[name].value = value
    setFormData(current)
  }

  const validateForm = () => {

    const keys = Object.keys(formData)
    let valid = true

    const state = { ...formData }

    keys.forEach((key) => {
      const current = formData[key]
      if (!OPTIONAL_FIELDS.includes(key) ) {

        if (current.value === '') {
          state[key].error =  (key.toLowerCase().includes('date')) ? 'Please enter a date' : 'Field required';
          valid = false
        } else if (key.toLowerCase().includes('email') && !VALID_EMAIL.test(current.value)) {
          state[key].error = 'Invalid email'
          valid = false
        } else {
          state[key].error = ''
        }
      }
    })

    if (currentFiles !== null && currentFiles.length > 0) {

      var currentError = '';

      currentFiles.forEach((currentFile) => {
        let fileSizeInMB = currentFile.size / (1024 * 1024); // convert from bytes to MB
        if (fileSizeInMB > 10) {
          valid = false;
          currentError = 'File size exceeds 10 MB';
        }
      })

      setFileError(currentError)

      
    }

    if(recaptchaRef.current.getValue() === '') {
      valid = false;
    }



    setFormData(state)

    return valid

  }

  const submitForm = async () => {

    if (loading) {
      return
    }

    if (validateForm()) {

      const form = {}

      form['token'] = recaptchaRef.current.getValue()
      form['formType'] = 'ccf'


      Object.keys(formData).forEach((key) => {
        switch(key) {
          case 'state':
            form[key] = STATES[formData[key].value].abbreviation
            break;
          case 'conflictDate':
          case 'competitorDate':
            if(formData[key].value && formData[key].value !== "") {
              form[key] = formData[key].value.toLocaleDateString("en-US");
            }
          break;
          default: 
            if(key !== 'competitorDocuments' && formData[key].value !== "") {
              form[key] = formData[key].value
            }
        }
      })

      setErrorMessage('');

      setLoading(true)

      const selectedRole = roles[roleIndex]
      const selectedCategory = categories[categoryIndex]

      postData({
        ...userData,
        ...form,
      }, currentFiles).then((response) => {

        ReactGA.event(GA_EVENTS.acc_form_submitted, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_form_type: userData?.requestType,
          acc_form_city: form['city'],
          acc_form_state: form['state'],
          acc_form_propertyId: form['propertyId'],
          acc_form_propertyName: form['propertyName'],
        })

        setLoading(false)
        dispatch(updateState({
          view: COMPLETE,
          ticket: response.ticket.ticketNumber
        }))

      }).catch(err => {
        
        ReactGA.event(GA_EVENTS.acc_form_invalid, {
          acc_role_id: selectedRole?.id,
          acc_role_name: selectedRole?.name,
          acc_category_id: selectedCategory?.id,
          acc_category_name: selectedCategory?.name,
          acc_subcategory_id: subcategory?.subcategory ? subcategory.subcategory?.toLowerCase() : '',
          acc_subcategory_name: subcategory?.subcategory,
          acc_error: err.message,
        })
        setErrorMessage(err.message || 'An error occurred, please try again.')
        setLoading(false)

      })

    } else {
      setErrorMessage('Please fix any invalid form fields.')
    }

  }

  return (
    <div className="ccf-form container">

      {categoryIndex > -1 && <div className="row">
        <div className="grid-col mar-t-xs">
          <Breadcrumbs level={subcategory ? 3 : 1} />
        </div>
      </div>}

      <div className="row">
        <div className="grid-col-12 grid-col">
          
          <div className='mar-b-sm'>
            <h4 className="heading-md mar-b-md">Competitor Conflict Form:</h4>
            {/* <p>This form is intended for conflicting service issues where the previous customer at the service address did not disconnect their service (Working Service Conflict). A manual order is required prior to submission of this form.</p> */}
          </div>
          
          <div className="pad-b-md-md pad-b-sm-lg pad-b-lg-sm">

          <div className="form-row">
                <TextField
                  id="propertyName"
                  value={formData["propertyName"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="propertyName"
                  disabled={loading}
                  error={formData["propertyName"].error.length > 0}
                  errorText={formData["propertyName"].error}
                  labelText="Property name(s)"
                  type={TextFieldType.TEXT} />
              </div>

              <div className="form-row">
                <TextField
                  id="propertyId"
                  value={formData["propertyId"].value}
                  onChange={handleFieldChange}
                  maxLength={50}
                  name="propertyId"
                  labelText="ACC Property ID"
                  disabled={loading}
                  error={formData["propertyId"].error.length > 0}
                  errorText={formData["propertyId"].error}
                  type={TextFieldType.TEXT} />
              </div>


          <div className="form-row">
              <TextField
                id="address1"
                value={formData["address1"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="address1"
                labelText="Address 1"
                disabled={loading}
                error={formData["address1"].error.length > 0}
                errorText={formData["address1"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <TextField
                id="address2"
                value={formData["address2"].value}
                onChange={handleFieldChange}
                maxLength={50}
                name="address2"
                labelText="Address 2"
                disabled={loading}
                error={formData["address2"].error.length > 0}
                errorText={formData["address2"].error}
                type={TextFieldType.TEXT} />
            </div>

            <div className="form-row">
              <div className="row">
                <TextField
                  id="city"
                  value={formData["city"].value}
                  onChange={handleFieldChange}
                  maxLength={30}
                  name="city"
                  labelText="City"
                  disabled={loading}
                  error={formData["city"].error.length > 0}
                  errorText={formData["city"].error}
                  type={TextFieldType.TEXT} />
              </div>
            </div>
            <div className="form-row">
              <div className="row">
                <div className="grid-col-7 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <div className="select-container">
                    <Select
                      id="state"
                      label="State"
                      value={formData["state"].value}
                      name="state"
                      disabled={loading}
                      errorMsg={formData["state"].error !== '' && formData["state"].error}
                      onChange={({selectedIndex}) => {
                        handleInputChange('state', selectedIndex)
                      }}
                      >
                      {STATES.map((state) => <Select.Option key={`state_${state.abbreviation}`} value={state.name} />)}
                    </Select>
                  </div>
                </div>
                <div className="grid-col-5 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="zip"
                    value={formData["zip"].value}
                    onChange={handleFieldChange}
                    maxLength={10}
                    name="zip"
                    labelText="ZIP"
                    disabled={loading}
                    error={formData["zip"].error.length > 0}
                    errorText={formData["zip"].error}
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            <div className="form-row mar-b-md">
              <hr />
            </div>

          <div className="form-row">
            <div className="row">
              <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                <TextField
                  id="submitterFirstName"
                  value={formData["submitterFirstName"].value}
                  onChange={handleFieldChange}
                  maxLength={30}
                  name="submitterFirstName"
                  disabled={loading}
                  error={formData["submitterFirstName"].error.length > 0}
                  errorText={formData["submitterFirstName"].error}
                  labelText="Submitter first name"
                  type={TextFieldType.TEXT} />
              </div>
              <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                <TextField
                  id="submitterLastName"
                  value={formData["submitterLastName"].value}
                  onChange={handleFieldChange}
                  maxLength={30}
                  name="submitterLastName"
                  disabled={loading}
                  error={formData["submitterLastName"].error.length > 0}
                  errorText={formData["submitterLastName"].error}
                  labelText="Submitter last name"
                  type={TextFieldType.TEXT} />
              </div>
            </div>
          </div> 

          <div className="form-row">
            <div className="row">
              <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                <TextField
                  id="submitterPhone"
                  value={formData["submitterPhone"].value}
                  onChange={handleFieldChange}
                  maxLength={20}
                  name="submitterPhone"
                  disabled={loading}
                  error={formData["submitterPhone"].error.length > 0}
                  errorText={formData["submitterPhone"].error}
                  labelText="Submitter phone"
                  type={TextFieldType.TEXT} />
              </div>
              <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                <TextField
                  id="submitterEmail"
                  value={formData["submitterEmail"].value}
                  onChange={handleFieldChange}
                  maxLength={255}
                  name="submitterEmail"
                  disabled={loading}
                  error={formData["submitterEmail"].error.length > 0}
                  errorText={formData["submitterEmail"].error}
                  labelText="Submitter email"
                  type={TextFieldType.TEXT} />
              </div>
            </div>
          </div>  

          <div className="form-row mar-b-md">
              <hr />
            </div>

            <h4 className="heading-md mar-t-md mar-b-md">Conflict Information:</h4>

            <div className="form-row">
              <div className="formfield-label font-bold color-gray-700 mar-b-xxs">Who learned of competitor conflict?</div>
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <TextField
                    id="conflictName"
                    value={formData["conflictName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="conflictName"
                    disabled={loading}
                    error={formData["conflictName"].error.length > 0}
                    errorText={formData["conflictName"].error}
                    labelText="Name"
                    type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="conflictTitle"
                    value={formData["conflictTitle"].value}
                    onChange={handleFieldChange}
                    maxLength={255}
                    name="conflictTitle"
                    disabled={loading}
                    error={formData["conflictTitle"].error.length > 0}
                    errorText={formData["conflictTitle"].error}
                    labelText="Title"
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <label className='formfield-label custom-label'>When did AT&T learn of conflict?</label>
                  <div className='date-picker'>
                  <DatePicker
                    id="conflictDate"
                    activeStartDate={new Date('01/01/1970')}
                    activeEndDate={new Date()}
                    errorMsg={formData["conflictDate"].error}
                    selectedDate={formData["conflictDate"].value}
                    placeHolderText='mm/dd/yyyy'
                    onSelect={(value) => handleDateChange('conflictDate',value)}
                    />
                    </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <TextArea
                id="conflictDescription"
                input={formData["conflictDescription"].value}
                name="conflictDescription"
                disabled={loading}
                error={formData["conflictDescription"].error.length > 0}
                errorText={formData["conflictDescription"].error}
                onChange={handleTextAreaChange}
                maxLength={500}
                labelText="How did AT&T learn of conflict?"
                placeHolder="" />
                <p><small>If property informed you of the conflict, please provide property contact name, title and email
                </small></p>
            </div>

            <div className="form-row mar-b-md">
              <hr />
            </div>

            <h4 className="heading-md mar-t-md mar-b-md">Competitor Information:</h4>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <TextField
                    id="competitorName"
                    value={formData["competitorName"].value}
                    onChange={handleFieldChange}
                    maxLength={30}
                    name="competitorName"
                    disabled={loading}
                    error={formData["competitorName"].error.length > 0}
                    errorText={formData["competitorName"].error}
                    labelText="Who is the competitor?"
                    type={TextFieldType.TEXT} />
                </div>
                <div className="grid-col-6 pad-l-none-sm pad-r-none pad-t-none-md pad-b-none-md pad-t-none-lg pad-b-none-lg">
                  <TextField
                    id="competitorType"
                    value={formData["competitorType"].value}
                    onChange={handleFieldChange}
                    maxLength={255}
                    name="competitorType"
                    disabled={loading}
                    error={formData["competitorType"].error.length > 0}
                    errorText={formData["competitorType"].error}
                    labelText="What type of competitor agreement (if any)?"
                    type={TextFieldType.TEXT} />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="row">
                <div className="grid-col-6 pad-l-none pad-r-none-sm pad-t-none pad-b-none-md pad-b-none-lg">
                  <label className='formfield-label custom-label'>When was the competitor agreement effective?</label>
                  <div className='date-picker'>
                  <DatePicker
                    id="competitorDate"
                    activeStartDate={new Date('01/01/1970')}
                    activeEndDate={new Date()}
                    errorMsg={formData["competitorDate"].error}
                    selectedDate={formData["competitorDate"].value}
                    placeHolderText='mm/dd/yyyy'
                    onSelect={(value) => handleDateChange('competitorDate',value)}
                    />
                    </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label className="formfield-label flex" htmlFor="file">Please upload any relevant documents confirming conflict (website, pictures of marketing, email correspondence, etc.)</label>
              <div className="flex flex-items-center">
                <button onClick={() => {
                  document.getElementById('file').click()
                }} className="btn-primary btn-small">Select File(s)</button>
                <span className='file-label pad-l-xxs'>{currentFiles ? currentFiles.length + ` file${currentFiles.length > 1 ? 's' : ''} selected` : 'No files selected'}</span>
              </div>
              <input
                className='hide'
                type="file"
                accept={FILE_TYPES.join(',')}
                id="file"
                name="file"
                multiple
                onChange={((e) => {
                  const files = document.getElementById('file').files
                  if (files.length > 0) {
                    setCurrentFiles([...files])
                  } else {
                    setCurrentFiles(null)
                  }
                })}
                disabled={loading}
                />
              {fileError.length > 0 && <div className='error'><div className='formfield-msg'>{fileError}</div></div>}
            </div>

           

            </div>

            <div className="form-row">
              <div className="pad-b-xs">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcFzUMpAAAAAO5TrQaycNzUvwo_bxZvOCDN0GYX"
                />
              </div>
              <hr />
            </div>

            {errorMessage.length > 0 && <div className="form-row">
              <p className="type-sm color-red-600">{errorMessage}</p>
            </div>}

            <div className="form-row">

              {loading === false && <button className='btn-primary' onClick={() => {
                submitForm()
              }}>Submit</button>}              

              {loading === true && <button className="btn-primary flex" type="button" disabled="disabled">
                <svg viewBox="0 0 43 43" focusable="false" className="loading-spinner">
                  <g fill="none">
                    <circle r="20" cx="21.5" cy="21.5" stroke="#878c94" strokeWidth="3" opacity=".25" />
                    <circle r="20" cx="21.5" cy="21.5" className="loading-spinner-outer" strokeDasharray="89,200" stroke="#009fdb" strokeWidth="3" strokeLinecap="round" />
                  </g>
                </svg>
                <span>Sending...</span>
              </button>}

            </div>
          </div>

        </div>
      </div>
  )
}

export default CompetitorConflictForm