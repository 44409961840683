import {
  Accordion
} from "@att-bit/duc.components.accordion";
import {
  AccordionItem, AccordionItemVariant,
} from "@att-bit/duc.components.accordion-item";
import {
  Button
} from "@att-bit/duc.components.button";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleFaqs, updateFaq, updateSeeAllFaqs } from "store/slices/applicationSlice";
import './Faq.scss';

const Faq = () => {
  const dispatch = useDispatch();
  
  const { faqs, faqIds, seeAllFaqs, selection: { role } } = useSelector(state => state.application);

  const [displayedFaqs, setDisplayedFaqs] = useState([]);
  const [areSomeFaqsCollapsed, setAreSomeFaqsCollapsed] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);

  useEffect(() => {
    let displayedFaqs = faqs.filter(faq => !faq.roles || faq.roles.includes(role));
    let maxInitialFaqs = document.documentElement.clientWidth < 700 ? 5 : 10;
    if (displayedFaqs.length > maxInitialFaqs) {
      if (!seeAllFaqs){
        displayedFaqs = displayedFaqs.slice(0, maxInitialFaqs);
      }
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
    setAreSomeFaqsCollapsed(displayedFaqs.some(({ id }) => faqIds[id] === false));    
    setDisplayedFaqs(displayedFaqs);
  }, [faqs, role, seeAllFaqs, faqIds, setAreSomeFaqsCollapsed, setDisplayedFaqs]);

  return (
    <div className="container">
      <div className="row">
        <div className="grid-col-12">
          <div id="faq-block" className="faq mar-t-sm">
            <button 
              onClick={() => dispatch(toggleFaqs(areSomeFaqsCollapsed))}
              className="toggle-link btn-reset link-text-3 solo"
            >
              {areSomeFaqsCollapsed ? 'Expand All' : 'Collapse All'}
            </button>
            
            <Accordion
              title="Frequently asked questions"
              titleClassName="faq-title heading-lg font-regular"
              withExpand={false}
              name="Accordion_FAQ"
              expandAlignment="right"
              expandBtnClassName="mar-b-xs"
              theme="theme-neutral-bg"
              classNames="pad-t-sm pad-b-md color-gray-700 bg-gray-200 accordion"
            >              
              {displayedFaqs.map(({ title, content, id }, index) => {
                return <div id={`${id}`} key={`faq_${id}`} ><AccordionItem isOpen={faqIds[id]} onToggle={(isOpen) => {
                  dispatch(updateFaq({
                    id,
                    isOpen,
                  }))
                }} variant={AccordionItemVariant.SMALL}>
                  <AccordionItem.Title className={"type-base"}>
                    {title}
                  </AccordionItem.Title>
                  <AccordionItem.Body>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </AccordionItem.Body>
                </AccordionItem>
                </div>
              })}
            </Accordion>
            {showMoreButton && 
              <div className="text-center pad-t-md-all">
                <Button
                  onClick={() => {
                    dispatch(updateSeeAllFaqs(!seeAllFaqs))
                  }}            
                  variant='btn-secondary'
                >
                {seeAllFaqs ? 'View less' : 'View more'}
               </Button>
              </div>
            }            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq