import ReactGA from 'react-ga4'

const GA_EVENTS = {
  pageview: 'pageview',
  
  acc_role_selected: 'acc_role_selected',
  acc_category_selected: 'acc_category_selected',
  acc_subcategory_selected: 'acc_subcategory_selected',
  acc_helpful_link_clicked: 'acc_helpful_link_clicked',
  acc_create_another_clicked: 'acc_create_another_clicked',
  acc_view_external_alternate_form_clicked: 'acc_view_external_alternate_form_clicked',
  acc_support_link_clicked: 'acc_support_link_clicked',
  acc_view_form_clicked: 'acc_view_form_clicked',
  acc_form_invalid: 'acc_form_invalid',
  acc_form_submitted: 'acc_form_submitted',
  acc_resolved_clicked: 'acc_resolved_clicked',
}

export { GA_EVENTS, ReactGA }