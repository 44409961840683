import './PromoModal.scss'
import { useState, useEffect } from 'react'
import { Modal, ModalSize, ModalVariant } from '@att-bit/duc.components.modal'
import ModalImage from 'images/reskin/promo-modal-header-2024-06.gif'
 

export const PromoModal = () => {
  const MODAL_PROMO_KEY = 'fiber_on_challenge_viewed'
  const [showPromo, setShowPromo] = useState(false)

  // useEffect(() => {
  //   if (!localStorage.getItem(MODAL_PROMO_KEY)) setShowPromo(true)
  // })

  const closeModal = () => {
    localStorage.setItem(MODAL_PROMO_KEY, true)
  }

   return (
    <>
      {showPromo &&
      <Modal onClose={closeModal}
        size={ModalSize.LARGE}
        scrimZindex={30}
        variant={ModalVariant.IMAGE}
        closeAriaLabel={'close modal'}
        aria-label={'modal promo aria label'}
        aria-modal="true">
        <Modal.Image>
          <div className='image-content'>
            <h1 className='heading-lg'>
              Enter for a chance to 
              <span className='heading-bump'>
                win a <sup>$</sup><span>500</span><br /> gift card
              </span>
              for your community
            </h1>
          </div>
          <img src={ModalImage}/>
        </Modal.Image>
        <Modal.Body>
          <div className="text-center">
            <p className="mar-b-xs mar-t-md-all pad-l-md-lg pad-r-md-lg pad-l-lg-md pad-r-lg-md pad-r-lg-sm pad-l-lg-sm">
              Join the AT&T Fiber Challenge.<br />
              The communities with the most AT&T Fiber&reg; sign-ups win.
            </p>
            <div className="pad-b-md-lg pad-l-md-lg pad-r-md-lg pad-l-lg-md pad-r-lg-md pad-r-lg-sm pad-l-lg-sm">
              <div className="inline-flex flex-column">
                <div className="inline-flex flex-column button-wrap mar-r-xxs mar-l-xxs mar-t-xs">
                  <a className="btn-primary" href='https://www.att.com/fiberchallenge' target="_blank" rel="noreferrer">att.com/fiberchallenge</a>
                </div>
              </div>
              <div className="pad-t-md-lg type-sm">
                For complete contest rules, <a href='https://www.att.com/fiberchallengerules' target="_blank" rel="noreferrer">click here</a>.
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      }
    </>
  );
};

export default PromoModal